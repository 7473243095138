import React from "react";
import "./baOnboardingChecklist.scss";
import { Timeline } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import OnboardingContainer from "../../../store/container/OnboardingContainer";
import { OnboardingReducerProps } from "../../../store/reducers/onboardingReducer";

interface BAOnboardingChecklistProps extends OnboardingReducerProps {}

function BAOnboardingChecklist({ activeStep }: BAOnboardingChecklistProps) {
  const timelineItems = [
    "Verify Aadhaar",
    "Personal Details",
    "Verify Pan",
    "Qualification",
    "Bank Details",
    "References",
    "Take a Selfie",
    "Submit",
  ];

  return (
    <div className="ba-onboarding-checklist">
      <div className="ba-onboarding-checklist__title">
        BA Onboarding Checklist
      </div>
      <Timeline className="ba-onboarding-checklist__timeline">
        {timelineItems.map((item, i) => (
          <Timeline.Item
            key={i}
            className={`${activeStep >= i ? "step-active" : ""}`}
          >
            {activeStep > i && (
              <CheckOutlined className="ba-onboarding-checklist__check-icon" />
            )}
            {item}
          </Timeline.Item>
        ))}
      </Timeline>
    </div>
  );
}

export default OnboardingContainer(BAOnboardingChecklist);
