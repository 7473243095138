import axios from "axios";
import { store } from "../store";
import { REQUEST_LOGOUT } from "../store/definitions/authConstants";
import { ApiRoutes } from "../routes/routeConstants/apiRoutes";
import Notification from "../shared/components/Notification";
import { NotificationTypes } from "../enums/notificationTypes";

export const getHeaders = (): any => {
  let headers, token;
  if (localStorage.getItem("token")) {
    token = JSON.parse(localStorage.getItem("token") || "{}");
  }
  headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token ? token["access_token"] : ""}`,
  };
  return headers;
};

const axiosInstance = axios.create({
  baseURL: ApiRoutes.BASE_URL,
  timeout: 120000,
});

axiosInstance.interceptors.request.use(function (config) {
  config.headers = getHeaders();
  return config;
});

axiosInstance.interceptors.response.use(
  (response): any => {
    return {
      data: response.data,
      message: response.statusText,
      status: response.status,
    };
  },
  (error) => {
    const { response } = error;
    Notification({
      type: NotificationTypes.ERROR,
      message:
        response?.data?.error || "Something went wrong. Please try again",
    });
    if (response.status === 401) {
      store.dispatch({ type: REQUEST_LOGOUT, payload: "Request for logout" });
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
