import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import "./appHeader.scss";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import logo from "../../../assets/images/logoLight.png";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import OnboardingContainer from "../../../store/container/OnboardingContainer";
import { OnboardingReducerProps } from "../../../store/reducers/onboardingReducer";

interface AppHeaderProps extends AuthReducerProps, OnboardingReducerProps {}

const AppHeader = ({
  authenticated,
  setUnAuthenticated,
  activeStep,
  setActiveStep,
}: AppHeaderProps) => {
  const history = useHistory();

  const location = useLocation();

  const [showHeader, setShowHeader] = useState(false);

  const handleClick = () => {};

  const handleLogout = () => {
    localStorage.clear();
    setUnAuthenticated();
    setActiveStep(0);
    history.push(AppRoutes.LOGIN);
  };

  useEffect(() => {
    setShowHeader(authenticated && location.pathname !== AppRoutes.VERIFY_OTP);
  }, [authenticated, location]);

  return showHeader ? (
    <Menu onClick={handleClick} mode="horizontal" className="app-header">
      <Menu.Item className="navbar-item" key="alipay">
        <img src={logo} alt="Logo" className="app-header__logo" />
      </Menu.Item>
      <Menu.Item
        onClick={handleLogout}
        className="navbar-item"
        key="logout"
        style={{ float: "right" }}
      >
        Logout
      </Menu.Item>
    </Menu>
  ) : (
    false
  );
};

export default OnboardingContainer(AuthContainer(AppHeader));
