import { ReferenceContact } from "../ReferenceContact/referenceContact.model";
import { BankDetail } from "../BankDetail/bankDetail.model";
import { Qualification } from "../Qualification/qualification.model";
import { Address } from "../Address/address.model";
import { serializable, alias, object, list, primitive } from "serializr";
import { AddressTypeEnum } from "../../enums/addressType.enum";
import { QualificationTypeEnum } from "../../enums/qualificationType.enum";
import { FileAttachment } from "../FileAttachment/fileAttachment.model";
import { ProfileCompletionStatusEnum } from "../../enums/profileCompletionStatus.enum";

export class BusinessAssociate {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("mobile", primitive()))
  mobile?: string;

  @serializable(alias("otp", primitive()))
  otp?: string;

  @serializable(alias("password_reset_otp", primitive()))
  passwordResetOTP?: string;

  @serializable(alias("password", primitive()))
  password?: string;

  @serializable(alias("profile_pic_id", primitive()))
  profilePicId?: number;

  @serializable(alias("latitude", primitive()))
  latitude?: number;

  @serializable(alias("longitude", primitive()))
  longitude?: number;

  @serializable(alias("profile_pic", object(FileAttachment)))
  profilePic?: FileAttachment;

  @serializable(alias("aadhaar_number", primitive()))
  aadhaarNumber?: string;

  @serializable(alias("pan_number", primitive()))
  panNumber?: string;

  @serializable(alias("profile_pic_url", primitive()))
  profilePicUrl?: string;

  @serializable(alias("aadhaar_front_page", object(FileAttachment)))
  aadhaarFrontPage?: FileAttachment;

  @serializable(alias("aadhaar_back_page", object(FileAttachment)))
  aadhaarBackPage?: FileAttachment;

  @serializable(alias("other_identity_proof", object(FileAttachment)))
  otherIdentityProof?: FileAttachment;

  @serializable(alias("other_identity_proof_id", primitive()))
  otherIdentityProofId?: number;

  @serializable(alias("aadhaar_front_page_id", primitive()))
  aadhaarFrontPageId?: number;

  @serializable(alias("aadhaar_back_page_id", primitive()))
  aadhaarBackPageId?: number;

  @serializable(alias("pan_card", object(FileAttachment)))
  panCard?: FileAttachment;

  @serializable(alias("pan_card_id", primitive()))
  panCardId?: number;

  @serializable(alias("profile_completion_status", primitive()))
  profileCompletionStatus?: ProfileCompletionStatusEnum;

  @serializable(alias("first_name", primitive()))
  firstName?: string;

  @serializable(alias("grant_type", primitive()))
  grantType?: string = "password";

  @serializable(alias("last_name", primitive()))
  lastName?: string;

  @serializable(alias("gender", primitive()))
  gender?: string;

  @serializable(alias("dob", primitive()))
  dob?: string;

  @serializable(alias("is_aadhaar_verified", primitive()))
  isAadhaarVerified?: boolean;

  @serializable(alias("is_pan_verified", primitive()))
  isPanVerified?: boolean;

  @serializable(alias("is_bank_details_verified", primitive()))
  isBankDetailsVerified: boolean = false;

  @serializable(alias("student_ba_agreed", primitive()))
  studentBAAgreed?: string;

  @serializable(alias("is_student_ba", primitive()))
  isStudentBA: boolean = false;

  @serializable(alias("source", primitive()))
  source?: string;

  @serializable(alias("addresses", list(object(Address))))
  addresses: Address[] = [];

  @serializable(alias("qualifications", list(object(Qualification))))
  qualifications?: Qualification[] = [
    Object.assign(new Qualification(), {
      ...new Qualification(),
      qualificationType: QualificationTypeEnum.EDUCATIONAL,
    }),
    Object.assign(new Qualification(), {
      ...new Qualification(),
      qualificationType: QualificationTypeEnum.PROFESSIONAL,
    }),
  ];

  @serializable(alias("bank_detail", object(BankDetail)))
  bankDetail?: BankDetail = new BankDetail();

  residentialAddress?: Address = Object.assign(new Address(), {
    ...new Address(),
    addressType: AddressTypeEnum.RESIDENTIAL,
  });

  communicationAddress?: Address = Object.assign(new Address(), {
    ...new Address(),
    addressType: AddressTypeEnum.COMMUNICATION,
  });

  @serializable(alias("reference_contacts", list(object(ReferenceContact))))
  referenceContacts: ReferenceContact[] = [];

  getAddresses() {
    this.communicationAddress =
      this.addresses.find(
        (address) => address.addressType === AddressTypeEnum.COMMUNICATION
      ) ??
      Object.assign(new Address(), {
        ...new Address(),
        addressType: AddressTypeEnum.COMMUNICATION,
      });
    if (this.communicationAddress) {
      this.communicationAddress.addressType = AddressTypeEnum.COMMUNICATION;
    }
    if (this.communicationAddress?.city) {
      this.communicationAddress.cityId = this.communicationAddress.city.id;
      this.communicationAddress.provinceId =
        this.communicationAddress?.city.provinceId;
    }
    this.residentialAddress =
      this.addresses.find(
        (address) => address.addressType === AddressTypeEnum.RESIDENTIAL
      ) ??
      Object.assign(new Address(), {
        ...new Address(),
        addressType: AddressTypeEnum.RESIDENTIAL,
      });
    if (this.residentialAddress?.city) {
      this.residentialAddress.cityId = this.residentialAddress.city.id;
      this.residentialAddress.provinceId =
        this.residentialAddress?.city.provinceId;
    }
  }

  setAddresses() {
    this.addresses = [];
    if (this.communicationAddress) {
      this.addresses.push(this.communicationAddress);
    }
    if (this.residentialAddress) {
      this.addresses.push(this.residentialAddress);
    }
  }

  setFormValues() {
    this.getAddresses();
    this.aadhaarFrontPageId = this.aadhaarFrontPage?.id;
    this.aadhaarBackPageId = this.aadhaarBackPage?.id;
    this.panCardId = this.panCard?.id;
    this.otherIdentityProofId = this.otherIdentityProof?.id;
    this.profilePicId = this.profilePic?.id;
    if (this.bankDetail) {
      this.bankDetail.cancelledChequeId = this.bankDetail?.cancelledCheque?.id;
      this.bankDetail.statementId = this.bankDetail?.statement?.id;
      this.bankDetail.isBankDetailsVerified = this.isBankDetailsVerified;
    }
  }
}
