import React, { ReactNode, useEffect, useState } from "react";
import "./verifyPanForm.scss";
import { Form, Formik } from "formik";
import InputField from "../../../shared/components/InputField";
import { Button, Row, Col } from "antd";
import { verifyPanFormValidation } from "./verifyPanFormValidation";
import OnboardingService from "../../../services/OnboardingService/onboarding.service";
import OnboardingContainer from "../../../store/container/OnboardingContainer";
import { OnboardingReducerProps } from "../../../store/reducers/onboardingReducer";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import useProfileCompletionStatus from "../../../shared/hooks/profileCompletionStatusHooks";
import AttachmentUpload from "../../../shared/components/AttachmentUpload";
import { BusinessAssociate } from "../../../models/BusinessAssociate/businessAssociate.model";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import AuthContainer from "../../../store/container/AuthContainer";
import { ProfileCompletionStatusEnum } from "../../../enums/profileCompletionStatus.enum";
import { StudentBAEnum } from "../../../enums/studentBA.enum";

interface VerifyPanFormProps extends OnboardingReducerProps, AuthReducerProps {
  onSuccess?: () => void;
}

interface VerifyPanProps {
  panNumber?: string;
}

function VerifyPanForm({
  activeStep,
  setActiveStep,
  onSuccess,
  businessAssociate,
}: VerifyPanFormProps) {
  const [formValues, setFormValues] = useState<BusinessAssociate>(
    businessAssociate || new BusinessAssociate()
  );

  const [panVerificationFailure, setPanVerificationFailure] = useState(false);

  const [formLoading, setFormLoading] = useState(false);

  const [verifyLoading, setVerifyLoading] = useState(false);

  const { panVerified } = useProfileCompletionStatus();

  const handleVerify = (values: VerifyPanProps) => {
    setVerifyLoading(true);
    if (values.panNumber) {
      OnboardingService.verifyPan(
        values.panNumber,
        (success: boolean) => {
          if (success) {
            onSuccess ? onSuccess() : setActiveStep(activeStep + 1);
          } else {
            setPanVerificationFailure(true);
          }
        },
        () => {},
        () => {
          setVerifyLoading(false);
        }
      );
    }
  };

  const handleSubmit = (values: VerifyPanProps) => () => {
    const businessAssociate = Object.assign(new BusinessAssociate(), values);
    businessAssociate.profileCompletionStatus =
      ProfileCompletionStatusEnum.PAN_COMPLETED;
    setFormLoading(true);
    OnboardingService.updateBADetails(
      businessAssociate,
      () => {
        setActiveStep(activeStep + 1);
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  useEffect(() => {
    setPanVerificationFailure(
      !!(businessAssociate?.panNumber && !businessAssociate?.isPanVerified)
    );
  }, []);

  return (
    <div className="verify-pan-form">
      <Formik
        enableReinitialize
        initialValues={formValues}
        onSubmit={handleVerify}
        validationSchema={verifyPanFormValidation}
      >
        {({ values, errors, isValid, dirty, setFieldValue }) => {
          return (
            <Form>
              <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <InputField
                    readOnly={panVerified}
                    title="PAN"
                    type="text"
                    name="panNumber"
                    placeholder="Enter PAN"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue("panNumber", e.target.value.toUpperCase());
                    }}
                  />
                  {panVerified && (
                    <div className="verify-pan-form__verified">
                      <CheckOutlined className="verify-pan-form__verified-icon" />
                    </div>
                  )}
                  {panVerificationFailure && (
                    <div className="verify-pan-form__failed">
                      <CloseOutlined className="verify-pan-form__failed-icon" />
                    </div>
                  )}

                  {!panVerified && (
                    <div className="verify-pan-form__submit">
                      <Button
                        className="secondary-btn"
                        htmlType="submit"
                        disabled={!isValid || verifyLoading}
                        loading={verifyLoading}
                      >
                        Verify {panVerificationFailure ? "Again" : ""}
                      </Button>
                      {businessAssociate?.studentBAAgreed ===
                        StudentBAEnum.AGREED_YES && (
                        <span className="verify-pan-form__submit mt-5 ">
                          <Button
                            className="primary-btn ml-5"
                            onClick={() => setActiveStep(activeStep + 1)}
                          >
                            Skip
                          </Button>
                        </span>
                      )}
                    </div>
                  )}

                  {panVerified && (
                    <div className="verify-pan-form__submit">
                      <Button
                        className="secondary-btn"
                        htmlType="button"
                        onClick={() => {
                          setActiveStep(activeStep + 1);
                        }}
                      >
                        Proceed
                      </Button>
                    </div>
                  )}
                  {panVerificationFailure && (
                    <React.Fragment>
                      <h3 className="text-primary"> Upload PAN document</h3>
                      <AttachmentUpload
                        accept="image/jpeg,image/png,image/jpg, application/pdf"
                        valueKey="panCard"
                        name="panCardId"
                        value={values?.panCard}
                        placeholder="Pan card (jpeg, png, pdf) (Max 5MB)"
                        setFieldValue={setFieldValue}
                      />
                    </React.Fragment>
                  )}
                </Col>
              </Row>
              {panVerificationFailure && (
                <div className="mt-3 text-right">
                  <Button
                    htmlType="button"
                    type="primary"
                    onClick={handleSubmit(values)}
                    disabled={formLoading || !values?.panCardId}
                    loading={formLoading}
                  >
                    Continue
                  </Button>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default AuthContainer(OnboardingContainer(VerifyPanForm));
