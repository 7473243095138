import * as Yup from "yup";

export const baPersonalDetailsFormValidation = Yup.object().shape({
  firstName: Yup.string().nullable().required("First name is required!"),
  lastName: Yup.string().nullable().required("Last name is required!"),
  gender: Yup.string().nullable().required("Gender is required!"),
  dob: Yup.string().nullable().required("DOB is required!"),
  residentialAddress: Yup.object().shape({
    street: Yup.string().nullable().required("Address is required!"),
    provinceId: Yup.string().nullable().required("Province is required!"),
    cityId: Yup.string().nullable().required("City is required!"),
    zipcode: Yup.string().nullable().required("Zipcode is required!"),
  }),
});
