import * as Yup from "yup";
import { QualificationTypeEnum } from "../../../enums/qualificationType.enum";

export const baBankDetailFormValidation = Yup.object().shape({
  bankDetail: Yup.object().shape({
    accountNumber: Yup.string()
      .nullable()
      .matches(/^[0-9]*$/, "Please enter valid account number!")
      .required("Account number is required!"),
    bankName: Yup.string()
      .nullable()
      .matches(/^[A-Za-z ]*$/, "Only characters are allowed!")
      .required("Bank name is required!"),
    accountHolderName: Yup.string()
      .nullable()
      .required("Account Holder name is required!"),
    ifscCode: Yup.string().nullable().required("IFSC code is required!"),
    statementId: Yup.string().when("isBankDetailsVerified", {
      is: false,
      then: Yup.string().nullable().required("Bank Statement is required"),
      otherwise: Yup.string().nullable(),
    }),
    cancelledChequeId: Yup.string()
      .nullable()
      .required("Cancelled cheque is required!"),
  }),
});
