import * as Yup from "yup";

export const verifyAadhaarFormValidation = Yup.object().shape({
  aadhaarNumber: Yup.string()
    .nullable()
    .required("Aadhaar number is required")
    .matches(/^[0-9]+$/, "Please enter only numbers")
    .min(12, "Please enter exactly 12 digits")
    .max(12, "Please enter exactly 12 digits"),
});
