import React, { useEffect, useCallback, useState } from "react";
import "./baOnboardingWrapper.scss";
import { Row, Col, Progress, Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import BAOnboardingChecklist from "../BAOnboardingChecklist";
import VerifyBADetails from "../VerifyBADetails";
import BAPersonalDetails from "../BAPersonalDetails";
import BAQualificationForm from "../BAQualificationForm";
import BABankDetailForm from "../BABankDetailForm";
import BAReferenceForm from "../BAReferenceForm";
import OnboardingContainer from "../../../store/container/OnboardingContainer";
import { OnboardingReducerProps } from "../../../store/reducers/onboardingReducer";
import BAProfilePictureForm from "../BAProfilePictureForm";
import BAAgreementForm from "../BAAgreementForm";
import { ProfileCompletionStatusEnum } from "../../../enums/profileCompletionStatus.enum";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import VerifyPanForm from "../VerifyPanForm";
import BAAgreementResponse from "../BAAgreementResponse";
import Modal from "antd/lib/modal/Modal";

import { BusinessAssociate } from "../../../models/BusinessAssociate/businessAssociate.model";
import OnboardingService from "../../../services/OnboardingService/onboarding.service";
import { StudentBAEnum } from "../../../enums/studentBA.enum";

interface BAOnboardingWrapperProps
  extends OnboardingReducerProps,
    AuthReducerProps {}

function BAOnboardingWrapper({
  activeStep,
  setActiveStep,
  businessAssociate,
  setAuthenticated,
}: BAOnboardingWrapperProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const handleGoBack = () => {
    setActiveStep(activeStep - 1);
  };

  const onboardingItems = [
    {
      percent: 15,
      component: <VerifyBADetails />,
    },
    {
      percent: 35,
      component: <BAPersonalDetails />,
    },
    {
      percent: 42,
      component: <VerifyPanForm />,
    },
    {
      percent: 49,
      component: <BAQualificationForm />,
    },
    {
      percent: 72,
      component: <BABankDetailForm />,
    },
    {
      percent: 81,
      component: <BAReferenceForm />,
    },
    {
      percent: 92,
      component: <BAProfilePictureForm />,
    },
    {
      percent: 100,
      component: <BAAgreementForm />,
    },
  ];

  const onboardingStepStatus = [
    { status: ProfileCompletionStatusEnum.AADHAAR_COMPLETED, step: 1 },
    { status: ProfileCompletionStatusEnum.PERSONAL_DETAILS_COMPLETE, step: 2 },
    { status: ProfileCompletionStatusEnum.PAN_COMPLETED, step: 3 },
    {
      status: ProfileCompletionStatusEnum.QUALIFICATION_DETAILS_COMPLETE,
      step: 4,
    },
    {
      status: ProfileCompletionStatusEnum.BANK_DETAILS_VERIFICATION_COMPLETE,
      step: 4,
    },
    { status: ProfileCompletionStatusEnum.BANK_DETAILS_COMPLETE, step: 5 },
    { status: ProfileCompletionStatusEnum.REFERENCES_COMPLETE, step: 6 },
    { status: ProfileCompletionStatusEnum.PROFILE_PIC_UPLOADED, step: 7 },
    { status: ProfileCompletionStatusEnum.AGREEMENT_REQUESTED, step: 8 },
    {
      status: ProfileCompletionStatusEnum.AGREEMENT_FIRST_INVITEE_SIGNED,
      step: 9,
    },
    {
      status: ProfileCompletionStatusEnum.AGREEMENT_SECOND_INVITEE_SIGNED,
      step: 10,
    },
  ];

  const handleSetActiveStep = useCallback(() => {
    const targetStep = onboardingStepStatus.find(
      (stepStatus) =>
        stepStatus.status === businessAssociate?.profileCompletionStatus
    );
    if (targetStep && activeStep === 0) {
      setActiveStep(targetStep.step);
    }
  }, []);

  useEffect(() => {
    handleSetActiveStep();
  }, []);

  useEffect(() => {
    if (
      activeStep === 0 &&
      businessAssociate?.studentBAAgreed === StudentBAEnum.AGREEMENT_NOT_MADE
    )
      setIsModalVisible(true);
  }, [activeStep]);

  const updateBADetails = (studentBAStatus: string) => {
    if (businessAssociate) {
      const ba = Object.assign(new BusinessAssociate(), {
        ...businessAssociate,
        studentBAAgreed: studentBAStatus,
      });
      setFormLoading(true);

      OnboardingService.updateBADetails(
        ba,
        () => {
          setAuthenticated(ba);
        },
        () => {},
        () => {
          setFormLoading(false);
          setIsModalVisible(false);
        }
      );
    }
  };

  const handleOk = () => {
    updateBADetails(StudentBAEnum.AGREED_YES);
  };
  const handleCancel = () => {
    updateBADetails(StudentBAEnum.AGREED_NO);
  };

  return (
    <div className="ba-onboarding-wrapper">
      {activeStep < 7 ? (
        <div>
          {activeStep > 0 && (
            <Button
              type="link"
              className="text-primary ba-onboarding-wrapper__back-btn"
              onClick={handleGoBack}
            >
              <h3>
                <LeftOutlined /> Back
              </h3>
            </Button>
          )}
          <Row gutter={[20, 20]}>
            <Col
              xs={24}
              sm={24}
              md={6}
              lg={6}
              xl={6}
              className="ba-onboarding-wrapper__checklist"
            >
              <h1>Welcome!</h1>
              <div className="ba-onboarding-wrapper__checklist-wrapper">
                <BAOnboardingChecklist />
              </div>
            </Col>
            <Col xs={24} sm={24} md={18} lg={18} xl={18}>
              <div className="ba-onboarding-wrapper__checklist-wrapper__progress-title text-primary">
                Progress
              </div>
              <Progress
                percent={onboardingItems[activeStep].percent}
                className="ba-onboarding-wrapper__progress"
              />
              <div className="ba-onboarding-wrapper__content">
                {onboardingItems[activeStep].component}
              </div>
            </Col>
            <Modal
              centered
              visible={isModalVisible}
              okText="Yes"
              cancelText="No"
              onOk={handleOk}
              onCancel={handleCancel}
              // confirmLoading={formLoading}
            >
              <p className="ba-onboarding-wrapper__modal-text text-primary">{`Are you a Student and joining Finobuddy under LEAD program?`}</p>
            </Modal>
          </Row>
        </div>
      ) : activeStep === 7 || activeStep === 8 ? (
        <BAAgreementForm />
      ) : (
        <BAAgreementResponse />
      )}
    </div>
  );
}

export default AuthContainer(OnboardingContainer(BAOnboardingWrapper));
