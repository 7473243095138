import React, { useEffect } from "react";
import AuthRouter from "../authRouters";
import "./authWrapper.scss";
import { Row, Col } from "antd";
import logo from "../../../assets/images/logoLight.png";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { useHistory, useLocation } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";

const AuthWrapper = ({ authenticated }: AuthReducerProps) => {
  const history = useHistory();

  const location = useLocation();

  useEffect(() => {
    if (authenticated && location.pathname !== AppRoutes.VERIFY_OTP) {
      history.push(AppRoutes.HOME);
    }
  }, [authenticated, location]);

  return (
    <div className="auth-wrapper">
      <Row className="auth-wrapper__content">
        <Col
          xs={24}
          sm={24}
          md={9}
          lg={9}
          xl={9}
          className="auth-wrapper__logo-content"
        >
          <div className="auth-wrapper__logo-wrapper">
            <img src={logo} alt="Logo" className="auth-wrapper__logo" />
          </div>
        </Col>
        <Col xs={24} sm={24} md={15} lg={15} xl={15}>
          <div className="auth-wrapper__form-wrapper">
            <div className="auth-wrapper__mobile-logo-wrapper">
              <img src={logo} alt="Logo" className="auth-wrapper__logo" />
            </div>
            <AuthRouter />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AuthContainer(AuthWrapper);
