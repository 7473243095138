import * as Yup from "yup";

export const baReferenceFormValidation = Yup.object().shape({
  referenceContacts: Yup.array().of(
    Yup.object().shape({
      name: Yup.string()
        .nullable()
        .required("Name is required!")
        .matches(/^[A-Za-z ]*$/, "Please enter valid name!"),
      email: Yup.string()
        .nullable()
        .required("Email is required!")
        .email("Please enter valid email!"),
      mobile: Yup.string()
        .required("Mobile number is required!")
        .nullable()
        .min(10, "Please enter 10 numbers!")
        .max(10, "Please enter 10 numbers!")
        .matches(/^[0-9]*$/, "Please enter valid number!"),
    })
  ),
});
