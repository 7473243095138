import React, { ReactNode, useEffect, useState } from "react";
import "./attachmentUpload.scss";
import { ErrorMessage, Field } from "formik";
import Error from "../Error";
import { Row, Col, Button } from "antd";
import { UploadOutlined, CloseOutlined } from "@ant-design/icons";
import OnboardingService from "../../../services/OnboardingService/onboarding.service";
import { FileAttachment } from "../../../models/FileAttachment/fileAttachment.model";
import AppLoader from "../AppLoader";

interface AttachmentUploadProps {
  name: string;
  title?: string;
  placeholder: string | ReactNode;
  multiple?: boolean;
  accept: string;
  setFieldValue: Function;
  value?: FileAttachment;
  valueKey: string;
  onReset?: () => void;
}

function AttachmentUpload(props: AttachmentUploadProps) {
  let inputRef: any;

  const {
    name,
    valueKey,
    title,
    placeholder,
    multiple,
    setFieldValue,
    accept,
    value,
    onReset,
  } = props;

  const [loading, setLoading] = useState(false);

  const [imageUrl, setImageUrl] = useState<string>();

  const handleClick = () => {
    inputRef?.click();
  };

  const handleReset = () => {
    setImageUrl(undefined);
    if (onReset) {
      onReset();
    } else {
      inputRef.value = null;
      setFieldValue(name, undefined);
      setFieldValue(valueKey, undefined);
    }
  };

  const handleChange = (e: any) => {
    if (multiple) {
      /*      const files: File[] = newAttachments.concat(Array.from(e.target.files));
      setFieldValue(name, files);*/
    } else {
      const file = e.target.files[0];
      setLoading(true);
      OnboardingService.uploadFile(
        file,
        (attachment: FileAttachment) => {
          setFieldValue(name, attachment.id);
          setImageUrl(attachment.attachmentUrl);
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    }
  };

  useEffect(() => {}, []);

  return (
    <Field name={name}>
      {({ field, form: { touched, errors }, meta }: any) => (
        <div className="attachment-upload">
          {title && <div className="attachment-upload__title">{title}</div>}
          <Row className="attachment-upload__attachment-list" gutter={[15, 15]}>
            <Col span={24} className="attachment-upload__img-wrapper">
              <div className="attachment-upload__input" onClick={handleClick}>
                {loading ? (
                  <AppLoader loading={loading} />
                ) : (
                  <React.Fragment>
                    <UploadOutlined className="mr-2" />
                    {placeholder}
                  </React.Fragment>
                )}
              </div>
              <input
                ref={(ref) => (inputRef = ref)}
                type="file"
                multiple={multiple}
                onChange={handleChange}
                accept={accept}
                style={{ display: "none" }}
              />
            </Col>
            {imageUrl ? (
              <div className="attachment-upload__attachment-wrapper">
                {imageUrl?.includes("pdf") ? (
                  <iframe src={imageUrl} className="attachment-upload__img" />
                ) : (
                  <img
                    src={imageUrl}
                    alt="Attachment"
                    className="attachment-upload__img"
                  />
                )}
                <Button
                  onClick={handleReset}
                  icon={<CloseOutlined />}
                  className="attachment-upload__close-btn"
                />
              </div>
            ) : value ? (
              <div className="attachment-upload__attachment-wrapper">
                {value?.attachmentUrl?.includes("pdf") ? (
                  <iframe
                    src={value.attachmentUrl}
                    className="attachment-upload__img"
                  />
                ) : (
                  <img
                    src={value.attachmentUrl}
                    alt="Attachment"
                    className="attachment-upload__img"
                  />
                )}
                <Button
                  onClick={handleReset}
                  icon={<CloseOutlined />}
                  className="attachment-upload__close-btn"
                />
              </div>
            ) : null}
          </Row>
          <ErrorMessage name={name}>
            {(message: string) => <Error message={message} />}
          </ErrorMessage>
        </div>
      )}
    </Field>
  );
}

export default AttachmentUpload;
