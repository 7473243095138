import React, { useState } from "react";
import { Formik, Form } from "formik";
import InputField from "../../../shared/components/InputField";
import { loginFormValidation } from "./loginFormValidation";
import { Button } from "antd";
import { withRouter } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import "./loginForm.scss";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import AuthService from "../../../services/AuthService/auth.service";
import { BusinessAssociate } from "../../../models/BusinessAssociate/businessAssociate.model";

const LoginForm = () => {
  const [formValues, setFormValues] = useState(new BusinessAssociate());

  const [formLoading, setFormLoading] = useState(false);

  const history = useHistory();

  const handleSubmit = (values: BusinessAssociate) => {
    const businessAssociate = Object.assign(new BusinessAssociate(), values);
    if(businessAssociate.email) {
      const regex = /\S+@\S+\.\S+/;
      const isEmail = regex.test(businessAssociate.email);
      if(isEmail) {
        businessAssociate.mobile = undefined;
      } else {
        businessAssociate.mobile = businessAssociate.email;
        businessAssociate.email = undefined;
      }
    }
    setFormLoading(true);
    AuthService.loginUser(
      businessAssociate,
      (businessAssociate: BusinessAssociate) => {
        history.push(AppRoutes.HOME);
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  return (
    <div className="login-form">
      <h1>Signin</h1>
      <Formik
        initialValues={formValues}
        onSubmit={handleSubmit}
        validationSchema={loginFormValidation}
      >
        {({ values, errors, isValid, dirty, setFieldValue }) => {
          return (
            <Form>
              <InputField
                title="Email/Phone"
                type="text"
                name="email"
                placeholder="Enter email/phone"
              />
              <InputField
                title="Password"
                type="password"
                name="password"
                placeholder="Enter password"
              />
              <div className="login-form__forgot-password text-right">
                <Link to={AppRoutes.FORGOT_PASSWORD}>Forgot Password?</Link>
              </div>
              <Button
                type="primary"
                htmlType="submit"
                disabled={!isValid || !dirty || formLoading}
                loading={formLoading}
              >
                Signin
              </Button>
              <div className="login-form__sign-up-wrapper">
                Don't have an account?{" "}
                <Link to={AppRoutes.REGISTER}>Signup</Link>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default withRouter(LoginForm);
