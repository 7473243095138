import { CreateReducer } from "../../shared/utils/createReducer";
import { SetActiveStepAction } from "../actions/onboardingActions";
import { SET_ACTIVE_STEP } from "../definitions/onboardingConstants";

export interface OnboardingState {
  activeStep: number;
}

export interface OnboardingReducerProps extends OnboardingState {
  setActiveStep: (activeStep: number) => SetActiveStepAction;
}

const initState: OnboardingState = {
  activeStep: 0,
};

const onboardingReducer = CreateReducer(initState, {
  [SET_ACTIVE_STEP](
    state: OnboardingState,
    action: SetActiveStepAction
  ): OnboardingState {
    const { activeStep } = action?.payload;
    return {
      ...state,
      activeStep,
    };
  },
});

export default onboardingReducer;
