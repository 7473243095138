export enum ProfileCompletionStatusEnum {
  PENDING_CREDENTIAL_VERIFICATION = "pending_credential_verification",
  EMAIL_VERIFIED = "email_verified",
  MOBILE_VERIFIED = "mobile_verified",
  CREDENTIALS_VERIFIED = "credentials_verified",
  AADHAAR_COMPLETED = "aadhaar_completed",
  PAN_COMPLETED = "pan_completed",
  PAN_DOCUMENT_UPLOADED = "pan_document_uploaded",
  PERSONAL_DETAILS_COMPLETE = "personal_details_complete",
  QUALIFICATION_DETAILS_COMPLETE = "qualification_details_complete",
  BANK_DETAILS_VERIFICATION_COMPLETE = "bank_details_verification_complete",
  BANK_DETAILS_COMPLETE = "bank_details_complete",
  REFERENCES_COMPLETE = "references_complete",
  PROFILE_PIC_UPLOADED = "profile_pic_uploaded",
  AGREEMENT_REQUESTED = "agreement_requested",
  AGREEMENT_SIGNED = "agreement_signed",
  AGREEMENT_FIRST_INVITEE_SIGNED = "agreement_first_invitee_signed",
  AGREEMENT_SECOND_INVITEE_SIGNED = "agreement_second_invitee_signed",
}
