import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { RootReducerProps } from "../reducers";
import * as OnboardingActions from "../actions/onboardingActions";

const mapStateToProps = ({ onboarding }: RootReducerProps, ownProps: any) => ({
  ...ownProps,
  activeStep: onboarding.activeStep,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(OnboardingActions, dispatch);

const OnboardingContainer = (component: any) => {
  return connect(mapStateToProps, mapDispatchToProps)(component);
};

export default OnboardingContainer;
