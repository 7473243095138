import React, { useEffect } from "react";
import "./verifyOTPWrapper.scss";
import {Button, Col, Row} from "antd";
import VerifyOTPForm from "../VerifyOTPForm";
import { OtpTypeEnum } from "../../../enums/otpType.enum";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { ProfileCompletionStatusEnum } from "../../../enums/profileCompletionStatus.enum";
import { CheckOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";

interface VerifyOTPWrapperProps extends AuthReducerProps {}

function VerifyOTPWrapper({ businessAssociate,setUnAuthenticated }: VerifyOTPWrapperProps) {

  const history = useHistory();

  useEffect(() => {
    if(businessAssociate?.profileCompletionStatus !== ProfileCompletionStatusEnum.PENDING_CREDENTIAL_VERIFICATION && businessAssociate?.profileCompletionStatus !== ProfileCompletionStatusEnum.EMAIL_VERIFIED) {
      history.push(AppRoutes.HOME);
    }
  }, []);

  const handleRetry = () => {
    localStorage.clear();
    setUnAuthenticated();
    history.push(AppRoutes.LOGIN);
  };
  
  return (
      <>
        <div className="verify-otp-wrapper">
          <Row gutter={[20, 0]} align="middle">
            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="right-border">
              {businessAssociate?.profileCompletionStatus ===
              ProfileCompletionStatusEnum.EMAIL_VERIFIED ? (
                <React.Fragment>
                  <div className="verify-otp-wrapper__email-verified">
                    <CheckOutlined />
                    <p>Email Verified</p>
                  </div>
                </React.Fragment>
              ) : (
                <VerifyOTPForm otpType={OtpTypeEnum.EMAIL}
                               businessAssociate={businessAssociate}
                />
              )}
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <VerifyOTPForm otpType={OtpTypeEnum.MOBILE}
                            disabled={
                              businessAssociate?.profileCompletionStatus !== ProfileCompletionStatusEnum.EMAIL_VERIFIED
                            }
                             businessAssociate={businessAssociate}
              />
            </Col>
          </Row>
          <Row  align="middle" justify="center" className="mt-5">
            <Col xs={24} sm={24} md={12} lg={12} xl={12} >
              <Button type="link" onClick={handleRetry}>
                Want to try again with different email and mobile number?
              </Button>
            </Col>
          </Row>
        </div>
      </>
  );
}

export default AuthContainer(VerifyOTPWrapper);
