import React, { FC, useEffect } from "react";
import { withRouter } from "react-router-dom";
import RestrictAccess from "../RestrictedAccess";
import AuthContainer from "../../../store/container/AuthContainer";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { ProfileCompletionStatusEnum } from "../../../enums/profileCompletionStatus.enum";

interface RequireAuthProps extends AuthReducerProps {
  history: any;
}

const requireAuth = (Component: any, allowedRoles: any = []) => {
  const Authentication = (props: RequireAuthProps) => {
    useEffect(() => {
      const { authenticated, history, businessAssociate } = props;
      if (!authenticated && history.location.pathname !== AppRoutes.LOGIN) {
        return history.push(AppRoutes.LOGIN);
      } else {
        if (
          authenticated &&
          (businessAssociate?.profileCompletionStatus ===
            ProfileCompletionStatusEnum.PENDING_CREDENTIAL_VERIFICATION ||
            businessAssociate?.profileCompletionStatus ===
              ProfileCompletionStatusEnum.EMAIL_VERIFIED) &&
          history.location.pathname !== AppRoutes.VERIFY_OTP
        ) {
          return history.push(AppRoutes.VERIFY_OTP);
        }
      }
    }, [props]);

    return <Component {...props} />;
  };
  return withRouter(AuthContainer(Authentication));
};

export const isAuthenticated = (component: FC) => {
  return requireAuth(component);
};

export default isAuthenticated;
