import * as Yup from "yup";
import { StudentBAEnum } from "../../../enums/studentBA.enum";

export const verifyPanFormValidation = Yup.object().shape({
  panNumber: Yup.string().when("studentBAAgreed", {
    is: StudentBAEnum.AGREED_YES,
    then: Yup.string()
      .nullable()
      .matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}/, "Please enter valid PAN number!"),
    otherwise: Yup.string()
      .nullable()
      .required("PAN number is required!")
      .matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}/, "Please enter valid PAN number!"),
  }),
});
