import { AUTHENTICATED, UNAUTHENTICATED } from "../definitions/authConstants";
import { ActionProps } from "../../shared/types/action.type";
import { BusinessAssociate } from "../../models/BusinessAssociate/businessAssociate.model";

export const setAuthenticated = (
  businessAssociate: BusinessAssociate
): ActionProps => ({
  type: AUTHENTICATED,
  payload: {
    authenticated: true,
    businessAssociate,
  },
});

export const setUnAuthenticated = (): ActionProps => {
  return {
    type: UNAUTHENTICATED,
    payload: {
      authenticated: false,
      user: null,
    },
  };
};
