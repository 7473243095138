import React, { useState } from "react";
import "./registerForm.scss";
import { Form, Formik } from "formik";
import InputField from "../../../shared/components/InputField";
import { Link, useHistory } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import { Button } from "antd";
import { BusinessAssociate } from "../../../models/BusinessAssociate/businessAssociate.model";
import AuthService from "../../../services/AuthService/auth.service";
import { registerFormValidation } from "./registerFormValidation";
import "./registerForm.scss";

const RegisterForm = () => {
  const [formValues, setFormValues] = useState(new BusinessAssociate());

  const [formLoading, setFormLoading] = useState(false);

  const history = useHistory();

  const handleSubmit = (values: BusinessAssociate) => {
    const businessAssociate = Object.assign(new BusinessAssociate(), values);
    setFormLoading(true);
    AuthService.registerUser(
      businessAssociate,
      (businessAssociate: BusinessAssociate) => {
        history.push(AppRoutes.VERIFY_OTP);
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  return (
    <div className="register-form">
      <h1>Signup</h1>
      <Formik
        initialValues={formValues}
        onSubmit={handleSubmit}
        validationSchema={registerFormValidation}
      >
        <Form>
          <InputField
            title="Email"
            type="email"
            name="email"
            placeholder="Enter email"
          />
          <InputField
            title="Phone"
            type="text"
            name="mobile"
            placeholder="Enter Phone"
          />
          <InputField
            title="Password"
            type="password"
            name="password"
            placeholder="Enter password"
          />
          <InputField
            title="Confirm Password"
            type="password"
            name="confirmPassword"
            placeholder="Enter password"
          />
          <Button type="primary" htmlType="submit" loading={formLoading}>
            Signup
          </Button>
          <div className="register-form__login-wrapper">
            Already have an account? <Link to={AppRoutes.LOGIN}>Signin</Link>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default RegisterForm;
