import * as Yup from "yup";
import { QualificationTypeEnum } from "../../../enums/qualificationType.enum";

export const qualificationFormValidation = Yup.object().shape({
  qualifications: Yup.array().of(
    Yup.object().shape({
      qualificationType: Yup.string().nullable(),
      institutionName: Yup.string()
        .nullable()
        .when("qualificationType", {
          is: QualificationTypeEnum.EDUCATIONAL,
          then: Yup.string()
            .nullable()
            .required("Institution name is required"),
          otherwise: Yup.string().nullable(),
        })
        .matches(/^[A-Za-z ]*$/, "Only characters are allowed!"),
      specialityName: Yup.string().when("qualificationType", {
        is: QualificationTypeEnum.EDUCATIONAL,
        then: Yup.string().nullable().required("Degree name is required"),
        otherwise: Yup.string().nullable(),
      }),
      yearOfCompletion: Yup.string().when("qualificationType", {
        is: QualificationTypeEnum.EDUCATIONAL,
        then: Yup.string()
          .nullable()
          .required("Year of completion is required"),
        otherwise: Yup.string().nullable(),
      }),
    })
  ),
});
