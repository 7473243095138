import React from "react";
import { Row, Col } from "antd";
import "./verifyBaDetails.scss";
import VerifyAadhaarForm from "../VerifyAadhaarForm";

interface VerifyBADetailsProps {}

function VerifyBADetails(props: VerifyBADetailsProps) {
  return (
    <div className="verify-ba-details">
      <h2 className="text-primary">Verify Aadhaar</h2>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <VerifyAadhaarForm />
        </Col>
      </Row>
    </div>
  );
}

export default VerifyBADetails;
