import axiosInstance from "../../interceptor/axiosInstance";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { BusinessAssociate } from "../../models/BusinessAssociate/businessAssociate.model";
import { deserialize, serialize } from "serializr";
import { Form } from "antd";
import { FileAttachment } from "../../models/FileAttachment/fileAttachment.model";
import { store } from "../../store";
import { AUTHENTICATED } from "../../store/definitions/authConstants";
import { BankDetail } from "../../models/BankDetail/bankDetail.model";

export default class OnboardingService {
  static initiateAadhaar(
    aadhaarNumber: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .post(ApiRoutes.INITIATE_AADHAAR, {
        aadhaar_number: aadhaarNumber,
      })
      .then((response) => {
        onSuccess(response.data.success);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static verifyAadhaar(
    otp: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .post(ApiRoutes.VERIFY_AADHAAR, {
        otp,
      })
      .then((response) => {
        onSuccess(response.data.success);
        OnboardingService.showBADetails(
          () => {},
          () => {},
          () => {}
        );
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static verifyPan(
    panNumber: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .post(ApiRoutes.VERIFY_PAN, {
        pan_number: panNumber,
      })
      .then((response) => {
        if (response.data.success) {
          OnboardingService.showBADetails(
            () => {},
            () => {},
            () => {}
          );
          Notification({
            type: NotificationTypes.SUCCESS,
            message: "PAN Verified",
            description: "Your PAN has been verified successfully.",
          });
        } else {
          Notification({
            type: NotificationTypes.ERROR,
            message: "PAN Verification failed",
            description: "Please upload your PAN document",
          });
        }
        onSuccess(response.data.success);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static uploadFile(
    attachment: File,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const formData = new FormData();
    formData.append("attachment", attachment);
    axiosInstance
      .post(ApiRoutes.UPLOAD_FILES, formData)
      .then((response) => {
        const uploadedFile = deserialize(
          FileAttachment,
          response.data["uploaded_file"]
        );
        onSuccess(uploadedFile);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateBADetails(
    businessAssociate: BusinessAssociate,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    businessAssociate.addresses = [];
    if (businessAssociate?.communicationAddress?.cityId) {
      businessAssociate.addresses.push(businessAssociate.communicationAddress);
    }
    if (businessAssociate?.residentialAddress?.cityId) {
      businessAssociate.addresses.push(businessAssociate.residentialAddress);
    }
    if (!businessAssociate?.bankDetail?.bankName) {
      delete businessAssociate.bankDetail;
    }
    const baJSON = {
      business_associate: serialize(businessAssociate),
    };
    axiosInstance
      .put(ApiRoutes.BA_PROFILE, baJSON)
      .then((response) => {
        const businessAssociate = deserialize(
          BusinessAssociate,
          response.data["business_associate"]
        );
        businessAssociate.setFormValues();
        localStorage.setItem(
          "businessAssociate",
          JSON.stringify(businessAssociate)
        );
        store.dispatch({
          type: AUTHENTICATED,
          payload: {
            authenticated: true,
            businessAssociate,
          },
        });
        onSuccess(businessAssociate);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static verifyAccount(
    bankDetail: BankDetail,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const bankDetailJSON = serialize(bankDetail);
    axiosInstance
      .post(ApiRoutes.VERIFY_ACCOUNT, bankDetailJSON)
      .then((response) => {
        if (response.data.success) {
          Notification({
            message: "Account details verified",
            type: NotificationTypes.SUCCESS,
          });
        }
        onSuccess(response.data.success);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static requestAgreement(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .post(ApiRoutes.REQUEST_AGREEMENT, {})
      .then((response) => {
        if (response.data["agreement_url"]) {
          onSuccess(response.data["agreement_url"]);
        }
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static showBADetails(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.BA_PROFILE)
      .then((response) => {
        const businessAssociate = deserialize(
          BusinessAssociate,
          response.data["business_associate"]
        );
        businessAssociate.setFormValues();
        localStorage.setItem(
          "businessAssociate",
          JSON.stringify(businessAssociate)
        );
        store.dispatch({
          type: AUTHENTICATED,
          payload: {
            authenticated: true,
            businessAssociate,
          },
        });
        onSuccess(businessAssociate);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }
}
