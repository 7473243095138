import { combineReducers } from "redux";
import authReducer, { AuthState } from "./authReducer";
import onboardingReducer, { OnboardingState } from "./onboardingReducer";

export interface RootReducerProps {
  auth: AuthState;
  onboarding: OnboardingState;
}

const RootReducer = combineReducers<RootReducerProps>({
  auth: authReducer,
  onboarding: onboardingReducer,
});

export default RootReducer;
