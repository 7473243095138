import React, { useState } from "react";
import VerifyBADetails from "../VerifyBADetails";
import "./baPersonalDetails.scss";
import { Form, Formik, FormikValues } from "formik";
import { Row, Col, Button, Checkbox, Modal } from "antd";
import { BusinessAssociate } from "../../../models/BusinessAssociate/businessAssociate.model";
import { baPersonalDetailsFormValidation } from "./baPersonalDetailsFormValidation";
import InputField from "../../../shared/components/InputField";
import DropdownField from "../../../shared/components/DropdownField";
import DatePickerField from "../../../shared/components/DatePickerField";
import AddressField from "../../../shared/components/AddressField";
import OnboardingContainer from "../../../store/container/OnboardingContainer";
import { OnboardingReducerProps } from "../../../store/reducers/onboardingReducer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import AuthContainer from "../../../store/container/AuthContainer";
import { ProfileCompletionStatusEnum } from "../../../enums/profileCompletionStatus.enum";
import OnboardingService from "../../../services/OnboardingService/onboarding.service";
import { Address } from "../../../models/Address/address.model";
import { AddressTypeEnum } from "../../../enums/addressType.enum";

interface BAPersonalDetailsProps
  extends OnboardingReducerProps,
    AuthReducerProps {}

function BAPersonalDetails({
  businessAssociate,
  activeStep,
  setActiveStep,
}: BAPersonalDetailsProps) {
  const [formLoading, setFormLoading] = useState(false);

  const [formValues, setFormValues] = useState<BusinessAssociate>(
    businessAssociate || new BusinessAssociate()
  );

  const [copyResidentialAddress, setCopyResidentialAddress] = useState(true);

  const genderOptions = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Others", value: "other" },
  ];

  const handleSubmit = (values: BusinessAssociate) => {
    const businessAssociate = Object.assign(new BusinessAssociate(), values);
    if (copyResidentialAddress) {
      businessAssociate.communicationAddress = Object.assign(new Address(), {
        ...businessAssociate.residentialAddress,
        addressType: AddressTypeEnum.COMMUNICATION,
      });
    }
    businessAssociate.profileCompletionStatus =
      ProfileCompletionStatusEnum.PERSONAL_DETAILS_COMPLETE;
    setFormLoading(true);
    OnboardingService.updateBADetails(
      businessAssociate,
      () => {
        setActiveStep(activeStep + 1);
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  return (
    <div className="ba-personal-details">
      {/*<VerifyBADetails />*/}
      <div className="ba-personal-details__form-wrapper">
        <h2 className="text-primary">Personal Details</h2>
        <Formik
          initialValues={formValues}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={baPersonalDetailsFormValidation}
        >
          {({
            values,
            errors,
            isValid,
            dirty,
            setFieldValue,
            setFieldTouched,
          }) => {
            return (
              <Form>
                <Row gutter={[20, 0]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <InputField
                      title="First name"
                      type="text"
                      name="firstName"
                      placeholder="Enter First name"
                      required
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <InputField
                      title="Last name"
                      type="text"
                      name="lastName"
                      placeholder="Enter Last name"
                      required
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <DropdownField
                      title="Gender"
                      name="gender"
                      options={genderOptions}
                      value={values.gender}
                      setFieldValue={setFieldValue}
                      placeHolder="Select Gender"
                      required
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <DatePickerField
                      title="DOB"
                      name="dob"
                      placeholder=""
                      /*disabledDate={(d) => {
                        const age = moment.duration(moment().diff(d));
                        return age.years() < 18;
                      }}*/
                      required
                      defaultValue={values.dob}
                      setFieldValue={setFieldValue}
                    />
                  </Col>
                  <AddressField
                    required
                    provinceKey="residentialAddress.provinceId"
                    provinceValue={values?.residentialAddress?.provinceId}
                    cityKey="residentialAddress.cityId"
                    cityValue={values?.residentialAddress?.cityId}
                    setFieldValue={setFieldValue}
                    values={values}
                    zipcodeKey="residentialAddress.zipcode"
                    streetKey="residentialAddress.street"
                  />
                  <Col span={24} className="mt-2 mb-2">
                    <Checkbox
                      checked={copyResidentialAddress}
                      onChange={(event) => {
                        setCopyResidentialAddress(event.target.checked);
                      }}
                    >
                      Save this as communication address
                    </Checkbox>
                  </Col>
                  {!copyResidentialAddress && (
                    <React.Fragment>
                      <Col span={24}>
                        <h2 className="text-primary mt-3">
                          Enter Communication Address
                        </h2>
                      </Col>
                      <AddressField
                        required
                        provinceKey="communicationAddress.provinceId"
                        provinceValue={values?.communicationAddress?.provinceId}
                        cityKey="communicationAddress.cityId"
                        cityValue={values?.communicationAddress?.cityId}
                        setFieldValue={setFieldValue}
                        values={values}
                        zipcodeKey="communicationAddress.zipcode"
                        streetKey="communicationAddress.street"
                      />
                    </React.Fragment>
                  )}
                </Row>
                <div className="ba-personal-details__submit-wrapper text-right">
                  <Button
                    type="primary"
                    className="ml-2"
                    htmlType="submit"
                    loading={formLoading}
                    disabled={!isValid || formLoading}
                  >
                    Proceed
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default AuthContainer(OnboardingContainer(BAPersonalDetails));
