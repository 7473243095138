import React, { useEffect, useState } from "react";
import "./baProfilePictureForm.scss";
import { Row, Col, Button, Modal } from "antd";
import AttachmentUpload from "../../../shared/components/AttachmentUpload";
import { BusinessAssociate } from "../../../models/BusinessAssociate/businessAssociate.model";
import { Form, Formik } from "formik";
import OnboardingContainer from "../../../store/container/OnboardingContainer";
import { OnboardingReducerProps } from "../../../store/reducers/onboardingReducer";
import { CheckOutlined } from "@ant-design/icons";
import { ProfileCompletionStatusEnum } from "../../../enums/profileCompletionStatus.enum";
import OnboardingService from "../../../services/OnboardingService/onboarding.service";
import Webcam from "react-webcam";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { FileAttachment } from "../../../models/FileAttachment/fileAttachment.model";
import { baProfilePictureFormValidation } from "./baProfilePictureFormValidation";

interface BAProfilePictureFormProps
  extends OnboardingReducerProps,
    AuthReducerProps {}

const videoConstraints = {
  facingMode: "user",
};

function BAProfilePictureForm({
  activeStep,
  setActiveStep,
  businessAssociate,
}: BAProfilePictureFormProps) {
  const [formValues, setFormValues] = useState(
    businessAssociate || new BusinessAssociate()
  );

  const [formLoading, setFormLoading] = useState(false);

  const [selfieUploadLoading, setSelfieUploadLoading] = useState(false);

  const [capturedSelfie, setCapturedSelfie] = useState<string>();

  const [showSelfieForm, setShowSelfieForm] = useState(false);

  const handleToggleSelfieForm = () => setShowSelfieForm(!showSelfieForm);

  const webcamRef: any = React.useRef(null);

  const handleUploadSelfie = () => {
    if (capturedSelfie) {
      fetch(capturedSelfie)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "Selfie", { type: "image/png" });
          setSelfieUploadLoading(true);
          OnboardingService.uploadFile(
            file,
            (uploadedFile: FileAttachment) => {
              handleToggleSelfieForm();
              setFormValues(
                Object.assign(new BusinessAssociate(), {
                  ...formValues,
                  profilePicId: uploadedFile.id,
                  profilePic: uploadedFile,
                })
              );
            },
            () => {},
            () => {
              setSelfieUploadLoading(false);
            }
          );
        });
    }
  };

  const capture = React.useCallback(() => {
    if (webcamRef && webcamRef?.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setCapturedSelfie(imageSrc);
    }
  }, [webcamRef]);

  const resetSelfie = () => setCapturedSelfie(undefined);

  const handleSubmit = (values: BusinessAssociate) => {
    const businessAssociate = Object.assign(new BusinessAssociate(), values);
    businessAssociate.profileCompletionStatus =
      ProfileCompletionStatusEnum.PROFILE_PIC_UPLOADED;
    setFormLoading(true);
    OnboardingService.updateBADetails(
      businessAssociate,
      () => {
        Modal.success({
          icon: null,
          className: "ba-profile-picture-form__success-modal",
          width: "35%",
          closable: true,
          okText: "Proceed for E-Signing",
          onOk: () => {
            OnboardingService.requestAgreement(
              (url: string) => {
                window.location.href = url;
              },
              () => {
                window.location.reload();
              },
              () => {
                setFormLoading(false);
              }
            );
          },
          okButtonProps: { style: { float: "none" } },
          content: (
            <div className="text-center">
              <div>
                <CheckOutlined className="ba-profile-picture-form__success-icon" />
              </div>
              <h2 className="text-primary">Thank You!</h2>
              <p className="text-secondary">
                You have submitted all your information successfully. Admin team
                will contact you to proceed further.
              </p>
            </div>
          ),
        });
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  useEffect(() => {
    const location = window.navigator && window.navigator.geolocation;
    if (location) {
      location.getCurrentPosition(
        (position) => {
          setFormValues(
            Object.assign(new BusinessAssociate(), {
              ...formValues,
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            })
          );
        },
        (error) => {}
      );
    }
  }, []);

  return (
    <div className="ba-profile-picture-form">
      <Row align="top">
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <h2 className="text-primary">Let's Take a Selfie (Max 5MB)</h2>
        </Col>
        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
          <Formik
            initialValues={formValues}
            onSubmit={handleSubmit}
            enableReinitialize
            validationSchema={baProfilePictureFormValidation}
          >
            {({ values, errors, isValid, dirty, setFieldValue }) => {
              return (
                <Form className="text-center">
                  <div className="mb-3">
                    <Button type="primary" onClick={handleToggleSelfieForm}>
                      Take a selfie
                    </Button>
                  </div>
                  <p className="text-secondary-black mb-3">(or)</p>
                  <AttachmentUpload
                    accept="image/jpeg,image/png,image/jpg"
                    name="profilePicId"
                    valueKey="profilePic"
                    value={values?.profilePic}
                    placeholder={<div>Upload from your device</div>}
                    setFieldValue={setFieldValue}
                  />
                  {(!values?.latitude || !values?.longitude) && (
                    <p className="text-secondary-black text-center mt-2">
                      Please enable your location to continue.
                    </p>
                  )}
                  <div className="text-right mt-5">
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={!isValid || formLoading}
                      loading={formLoading}
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Col>
      </Row>
      <Modal
        onCancel={handleToggleSelfieForm}
        visible={showSelfieForm}
        destroyOnClose
        footer={null}
        className="ba-profile-picture-form__modal"
      >
        {capturedSelfie ? (
          <React.Fragment>
            <img
              src={capturedSelfie}
              alt="Selfie"
              className="ba-profile-picture-form__selfie"
            />
            <div className="mt-5 text-right">
              <Button type="primary" onClick={resetSelfie}>
                Capture Again
              </Button>
              <Button
                type="primary"
                className="ml-2"
                loading={selfieUploadLoading}
                onClick={handleUploadSelfie}
              >
                Done
              </Button>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Webcam
              audio={false}
              height={720}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width={1280}
              videoConstraints={videoConstraints}
            />
            <div className="mt-2 text-right">
              <Button type="primary" onClick={capture}>
                Capture photo
              </Button>
            </div>
          </React.Fragment>
        )}
      </Modal>
    </div>
  );
}

export default AuthContainer(OnboardingContainer(BAProfilePictureForm));
