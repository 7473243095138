import React, { FC, useEffect, useState } from "react";
import { Router, Switch, Route, Redirect, useHistory } from "react-router-dom";
import { createBrowserHistory } from "history";
import { AppRoutes as appRoutes } from "./routeConstants/appRoutes";
import AuthWrapper from "../views/Auth/AuthWrapper";
import isAuthenticated from "../shared/components/HOC/requireAuth";
import { RouterProps } from "../shared/types/route.type";
import AppHeader from "../shared/components/AppHeader";
import BAOnboardingWrapper from "../views/BAOnboarding/BAOnboardingWrapper";
import AuthContainer from "../store/container/AuthContainer";
import { AuthReducerProps } from "../store/reducers/authReducer";
import OnboardingService from "../services/OnboardingService/onboarding.service";
import { BusinessAssociate } from "../models/BusinessAssociate/businessAssociate.model";
import AppLoader from "../shared/components/AppLoader";
import { ProfileCompletionStatusEnum } from "../enums/profileCompletionStatus.enum";

interface AppRoutesProps extends AuthReducerProps {}
export const appHistory = createBrowserHistory();

const AppRoutes = ({
  authenticated,
  setAuthenticated,
  businessAssociate,
}: AppRoutesProps) => {
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const routes: RouterProps[] = [
    { exact: false, path: appRoutes.AUTH, component: AuthWrapper },
    {
      exact: true,
      path: appRoutes.HOME,
      component: isAuthenticated(BAOnboardingWrapper),
    },
  ];

  useEffect(() => {
    if (authenticated) {
      setLoading(true);
      OnboardingService.showBADetails(
        (ba: BusinessAssociate) => {
          setAuthenticated(ba);
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
      if (
        businessAssociate?.profileCompletionStatus ===
        ProfileCompletionStatusEnum.PENDING_CREDENTIAL_VERIFICATION
      ) {
        appHistory.push(appRoutes.VERIFY_OTP);
      }
    }
  }, [authenticated]);

  return loading ? (
    <AppLoader loading={loading} />
  ) : (
    <div>
      <Router history={appHistory}>
        <AppHeader />
        <div className="app-wrapper">
          <Switch>
            {routes.map((route, index) => (
              <Route key={index} {...route} component={route.component as FC} />
            ))}
            <Route path="*" render={() => <Redirect to={appRoutes.LOGIN} />} />
          </Switch>
        </div>
      </Router>
    </div>
  );
};

export default AuthContainer(AppRoutes);
