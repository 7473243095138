import { AUTHENTICATED, UNAUTHENTICATED } from "../definitions/authConstants";
import { CreateReducer } from "../../shared/utils/createReducer";
import { ActionProps } from "../../shared/types/action.type";
import { BusinessAssociate } from "../../models/BusinessAssociate/businessAssociate.model";

export interface AuthState {
  authenticated: boolean;
  businessAssociate?: BusinessAssociate;
}

export interface AuthReducerProps extends AuthState {
  setAuthenticated: (businessAssociate: BusinessAssociate) => ActionProps;
  setUnAuthenticated: () => ActionProps;
}

const initState: AuthState = {
  authenticated: false,
  businessAssociate: undefined,
};

const authReducer = CreateReducer(initState, {
  [AUTHENTICATED](state: AuthState, action: ActionProps): AuthState {
    const { authenticated, businessAssociate } = action?.payload;
    return {
      ...state,
      authenticated,
      businessAssociate,
    };
  },
  [UNAUTHENTICATED](state: AuthState, action: ActionProps): AuthState {
    const { authenticated } = action?.payload;
    return { ...state, authenticated };
  },
});

export default authReducer;
