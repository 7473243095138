import React, { useEffect, useState } from "react";
import "./baAgreementForm.scss";
import { Button, Checkbox, Modal } from "antd";
import { BusinessAssociate } from "../../../models/BusinessAssociate/businessAssociate.model";
import { ProfileCompletionStatusEnum } from "../../../enums/profileCompletionStatus.enum";
import OnboardingService from "../../../services/OnboardingService/onboarding.service";
import AuthContainer from "../../../store/container/AuthContainer";
import OnboardingContainer from "../../../store/container/OnboardingContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { OnboardingReducerProps } from "../../../store/reducers/onboardingReducer";
import { CheckOutlined } from "@ant-design/icons";

interface BAAgreementFormProps
  extends AuthReducerProps,
    OnboardingReducerProps {}

function BAAgreementForm({
  activeStep,
  setActiveStep,
  businessAssociate,
}: BAAgreementFormProps) {
  const [formLoading, setFormLoading] = useState(false);

  const [requestFailure, setRequestFailure] = useState(false);

  const handleSubmit = () => {
    /*const ba = Object.assign(new BusinessAssociate(), businessAssociate);
    ba.profileCompletionStatus = ProfileCompletionStatusEnum.AGREEMENT_SIGNED;
    setFormLoading(true);
    OnboardingService.updateBADetails(
      ba,
      () => {
        Modal.success({
          icon: null,
          className: "ba-agreement-form__success-modal",
          width: "35%",
          closable: true,
          okText: "Close",
          onOk: () => {},
          okButtonProps: { style: { float: "none" } },
          content: (
            <div className="text-center">
              <div>
                <CheckOutlined className="ba-agreement-form__success-icon" />
              </div>
              <h2 className="text-primary">Thank You!</h2>
              <p className="text-secondary">
                You have submitted all your information successfully. Admin team
                will contact you to proceed further.
              </p>
            </div>
          ),
        });
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );*/
    setFormLoading(true);
    OnboardingService.requestAgreement(
      (url: string) => {
        window.location.href = url;
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };
  const [agreeTerms, setAgreeTerms] = useState(false);

  useEffect(() => {
    if (
      businessAssociate?.profileCompletionStatus !==
      ProfileCompletionStatusEnum.AGREEMENT_REQUESTED
    ) {
      OnboardingService.requestAgreement(
        (url: string) => {
          window.location.href = url;
        },
        () => {
          setRequestFailure(true);
        },
        () => {
          setFormLoading(false);
        }
      );
    }
  }, []);
  return (
    <div className="ba-agreement-form">
      <div>
        <h2 className="text-primary">
          {businessAssociate?.profileCompletionStatus ===
          ProfileCompletionStatusEnum.AGREEMENT_REQUESTED
            ? "Your profile has been submitted successfully. Please check your email for the e-sign agreement link."
            : requestFailure
            ? "Your profile has been submitted successfully. Our admin team will review your KYC documents and send out an e-sign agreement to your email."
            : "Redirecting you to e-sign the agreement..."}
        </h2>
      </div>
      {/*<div className="ba-agreement-form__content">
        <p>
          Cras gravida bibendum dolor eu varius. Morbi fermentum velit nisl,
          eget vehicula lorem sodales eget. Donec quis volutpat orci. Sed ipsum
          felis, tristique id egestas et, convallis ac velit. In consequat dolor
          libero, nec luctus orci rutrum nec. Phasellus vel arcu sed nibh ornare
          accumsan. Vestibulum in elementum erat. Interdum et malesuada fames ac
          ante ipsum primis in faucibus. <br />
          <br />
          Cras gravida bibendum dolor eu varius. Morbi fermentum velit nisl,
          eget vehicula lorem sodales eget. Donec quis volutpat orci. Sed ipsum
          felis, tristique id egestas et, convallis ac velit. In consequat dolor
          libero, nec luctus orci rutrum nec. Phasellus vel arcu sed nibh ornare
          accumsan. Cras gravida bibendum dolor eu varius. Morbi fermentum velit
          nisl, eget vehicula lorem sodales eget. Donec quis volutpat orci. Sed
          ipsum felis, tristique id egestas et, convallis ac velit. In consequat
          dolor libero, nec luctus orci rutrum nec.
        </p>
        <div>
          <Checkbox
            checked={agreeTerms}
            onChange={(event) => {
              setAgreeTerms(event.target.checked);
            }}
          >
            I agree the terms and conditions of this contract.
          </Checkbox>
        </div>
        <div className="mt-2 text-right">
          <Button
            type="primary"
            onClick={handleSubmit}
            disabled={!agreeTerms || formLoading}
            loading={formLoading}
          >
            Submit
          </Button>
        </div>
      </div>*/}
    </div>
  );
}

export default OnboardingContainer(AuthContainer(BAAgreementForm));
