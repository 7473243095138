import React, { useState, useEffect, SyntheticEvent } from "react";
import "./baBankDetailForm.scss";
import { Form, Formik } from "formik";
import { Button, Col, Row } from "antd";
import InputField from "../../../shared/components/InputField";
import { BusinessAssociate } from "../../../models/BusinessAssociate/businessAssociate.model";
import AttachmentUpload from "../../../shared/components/AttachmentUpload";
import OnboardingContainer from "../../../store/container/OnboardingContainer";
import { OnboardingReducerProps } from "../../../store/reducers/onboardingReducer";
import { ProfileCompletionStatusEnum } from "../../../enums/profileCompletionStatus.enum";
import OnboardingService from "../../../services/OnboardingService/onboarding.service";
import { baBankDetailFormValidation } from "./baBankDetailFormValidation";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import AuthContainer from "../../../store/container/AuthContainer";
import { BankDetail } from "../../../models/BankDetail/bankDetail.model";
import { StudentBAEnum } from "../../../enums/studentBA.enum";

interface BABankDetailFormProps
  extends OnboardingReducerProps,
    AuthReducerProps {}

function BABankDetailForm({
  setActiveStep,
  activeStep,
  businessAssociate,
}: BABankDetailFormProps) {
  const [formValues, setFormValues] = useState(
    businessAssociate || new BusinessAssociate()
  );

  const [formLoading, setFormLoading] = useState(false);

  const [verifyLoading, setVerifyLoading] = useState(false);

  const [enableProceed, setEnableProceed] = useState(false);

  const handleSubmit = (values: BusinessAssociate) => {
    const businessAssociate = Object.assign(new BusinessAssociate(), values);
    businessAssociate.profileCompletionStatus =
      ProfileCompletionStatusEnum.BANK_DETAILS_COMPLETE;
    setFormLoading(true);
    OnboardingService.updateBADetails(
      businessAssociate,
      () => {
        setActiveStep(activeStep + 1);
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  const handleVerifyAccount = (values: BusinessAssociate) => () => {
    const businessAssociate = Object.assign(new BusinessAssociate(), values);
    if (businessAssociate.bankDetail) {
      setVerifyLoading(true);
      OnboardingService.verifyAccount(
        businessAssociate.bankDetail,
        (success: boolean) => {
          if (success) {
            const ba = values;
            if (ba && ba.bankDetail) {
              ba.bankDetail.isBankDetailsVerified = true;
            }
            setFormValues(Object.assign(new BusinessAssociate(), ba));
          }
        },
        () => {
          const ba = values;
          if (ba && ba.bankDetail) {
            ba.bankDetail.isBankDetailsVerified = false;
          }
          setFormValues(Object.assign(new BusinessAssociate(), ba));
        },
        () => {
          setEnableProceed(true);
          setVerifyLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    if (businessAssociate) {
      setEnableProceed(
        !!(
          businessAssociate?.bankDetail?.isBankDetailsVerified ||
          businessAssociate?.bankDetail?.accountNumber
        )
      );
      if (!businessAssociate.bankDetail) {
        businessAssociate.bankDetail = new BankDetail();
        if (businessAssociate.studentBAAgreed !== StudentBAEnum.AGREED_YES) {
          businessAssociate.bankDetail.accountHolderName =
            businessAssociate.firstName + " " + formValues.lastName;
        }
        setFormValues(
          Object.assign(new BusinessAssociate(), businessAssociate)
        );
      }
    }
  }, [businessAssociate]);

  return (
    <div className="ba-bank-detail-form">
      <Formik
        initialValues={formValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={baBankDetailFormValidation}
      >
        {({ values, errors, isValid, dirty, setFieldValue }) => {
          return (
            <Form>
              <h2 className="text-primary">Bank Details</h2>
              <Row gutter={[20, 0]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <InputField
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue(
                        "bankDetail.accountNumber",
                        event.target.value
                      );
                      setEnableProceed(false);
                    }}
                    required
                    title="Account Number"
                    type="text"
                    name="bankDetail.accountNumber"
                    placeholder="Enter account number"
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <InputField
                    required
                    title="Bank name"
                    type="text"
                    name="bankDetail.bankName"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue("bankDetail.bankName", event.target.value);
                      setEnableProceed(false);
                    }}
                    placeholder="Enter Bank name"
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <InputField
                    required
                    readOnly={
                      values.studentBAAgreed !== StudentBAEnum.AGREED_YES
                    }
                    title="Account Holder name"
                    type="text"
                    name="bankDetail.accountHolderName"
                    placeholder="Enter Account holder name"
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <InputField
                    required
                    title="IFSC Code"
                    type="text"
                    name="bankDetail.ifscCode"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue("bankDetail.ifscCode", event.target.value);
                      setEnableProceed(false);
                    }}
                    placeholder="Enter IFSC code"
                  />
                </Col>
                {enableProceed && (
                  <React.Fragment>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <AttachmentUpload
                        value={values?.bankDetail?.statement}
                        accept="image/jpeg,image/png,image/jpg, application/pdf"
                        valueKey="bankDetail.statement"
                        name="bankDetail.statementId"
                        placeholder={`Bank statement (${
                          values?.bankDetail?.isBankDetailsVerified
                            ? "Optional"
                            : "Mandatory"
                        }) (Max 5MB)`}
                        setFieldValue={setFieldValue}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <AttachmentUpload
                        value={values?.bankDetail?.cancelledCheque}
                        accept="image/jpeg,image/png,image/jpg, application/pdf"
                        valueKey="bankDetail.cancelledCheque"
                        name="bankDetail.cancelledChequeId"
                        placeholder="Cancelled Cheque(Mandatory) (Max 5MB)"
                        setFieldValue={setFieldValue}
                      />
                    </Col>
                  </React.Fragment>
                )}
              </Row>
              <div className="mt-5 text-right">
                {enableProceed ? (
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="ml-2"
                    loading={formLoading}
                    disabled={!isValid || formLoading}
                  >
                    Proceed
                  </Button>
                ) : (
                  <Button
                    className="secondary-btn"
                    htmlType="button"
                    loading={verifyLoading}
                    disabled={verifyLoading}
                    onClick={handleVerifyAccount(values)}
                  >
                    Verify
                  </Button>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default AuthContainer(OnboardingContainer(BABankDetailForm));
