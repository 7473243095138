import * as Yup from "yup";

export const registerFormValidation = Yup.object().shape({
  email: Yup.string()
    .email("E-mail is not valid!")
    .required("E-mail is required!"),
  mobile: Yup.string()
    .required("Mobile number is required!")
    .min(10, "Please enter 10 digits")
    .max(10, "Please enter 10 digits"),
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Password must contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    )
    .required("Password is required!"),
  confirmPassword: Yup.string()
    .required("Password confirmation is required!")
    .oneOf([Yup.ref("password"), ""], "Passwords must match!"),
});
