import React from "react";
import "./baAgreementResponse.scss";
import OnboardingContainer from "../../../store/container/OnboardingContainer";
import { OnboardingReducerProps } from "../../../store/reducers/onboardingReducer";

interface BAAgreementResponseProps extends OnboardingReducerProps {}

function BAAgreementResponse({ activeStep }: BAAgreementResponseProps) {
  return (
    <div className="ba-agreement-response">
      <h1>
        {activeStep === 9
          ? "Congratulations. You've successfully signed the agreement. Our admin team will reach out to you with further updates."
          : "Congratulations. Your agreement has been signed. Instructions to download the BA mobile app will be sent out to you shortly."}
      </h1>
    </div>
  );
}

export default OnboardingContainer(BAAgreementResponse);
