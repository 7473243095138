import React, { useEffect, useState } from "react";
import "./verifyOTPForm.scss";
import { OtpTypeEnum } from "../../../enums/otpType.enum";
import OtpInput from "react-otp-input";
import { Button } from "antd";
import AuthService from "../../../services/AuthService/auth.service";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { ProfileCompletionStatusEnum } from "../../../enums/profileCompletionStatus.enum";
import moment from "moment";
import "moment-duration-format";
import { BusinessAssociate } from "../../../models/BusinessAssociate/businessAssociate.model";

interface VerifyOTPFormProps extends AuthReducerProps {
  otpType: OtpTypeEnum;
  disabled?: boolean;
  businessAssociate: BusinessAssociate;
}

function VerifyOTPForm({
  otpType,
  businessAssociate,
  disabled,
}: VerifyOTPFormProps) {
  const [otp, setOTP] = useState("");

  const [formLoading, setFormLoading] = useState(false);

  const [resendLoading, setResendLoading] = useState(false);

  const [disableResend, setDisableResend] = useState<boolean>(true);

  const [countdown, setCountdown] = useState<string>("03:00");

  const duration = 3 * 60; //3 minutes

  const interval = 1; //1 second

  const startCountdown = () => {
    let difference: any = moment.duration(duration * 1000, "milliseconds");
    setInterval(function () {
      if (difference > 0) {
        difference = moment.duration(
          difference - interval * 1000,
          "milliseconds"
        );
        setCountdown(difference.format("mm:ss", { trim: false }));
        if (!difference.seconds()) setDisableResend(false);
      }
    }, interval * 1000);
  };

  const handleResendOTP = () => {
    if (businessAssociate) {
      setResendLoading(true);
      AuthService.sendOTP(
        businessAssociate,
        otpType,
        () => {},
        () => {},
        () => {
          setResendLoading(false);
          setDisableResend(true);
        }
      );
    }
  };

  const history = useHistory();

  const handleSubmit = () => {
    setFormLoading(true);
    AuthService.verifyOTP(
      otp,
      otpType,
      () => {
        if (otpType === OtpTypeEnum.MOBILE) {
          history.push(AppRoutes.HOME);
        }
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  useEffect(() => {
    if (disableResend) startCountdown();
  }, [disableResend]);

  useEffect(() => {
    AuthService.sendOTP(
      businessAssociate,
      otpType,
      () => {},
      () => {},
      () => {}
    );
  }, []);

  return (
    <div className={`verify-otp-form ${disabled ? "disabled" : ""}`}>
      <h2 className="text-primary">{`${
        otpType === OtpTypeEnum.EMAIL ? "Email" : "Mobile"
      } OTP Verification`}</h2>
      <p>
        Please enter the verification code sent to{" "}
        {otpType === OtpTypeEnum.EMAIL
          ? businessAssociate?.email
          : businessAssociate?.mobile}
        .
      </p>
      {otpType === OtpTypeEnum.EMAIL && (
        <p style={{ fontSize: "12px" }}>
          (Please check your Junk/Spam folder if you've not received the OTP in
          the inbox)
        </p>
      )}
      <div className="verify-otp-form__input-wrapper">
        <OtpInput
          value={otp}
          onChange={(otp: string) => setOTP(otp)}
          numInputs={4}
          separator={<span>-</span>}
        />
      </div>
      <div className="text-center mt-4">
        <Button
          type="primary"
          loading={formLoading}
          disabled={otp.length !== 4 || formLoading || disabled}
          onClick={handleSubmit}
        >
          Verify
        </Button>
      </div>
      <div className="mt-2">
        <span className="text-secondary-black">Didn't receive the code? </span>
        <span className="text-secondary-black">{countdown} </span>
        <Button
          type="link"
          onClick={handleResendOTP}
          loading={resendLoading}
          disabled={disableResend}
        >
          Resend {otpType === OtpTypeEnum.EMAIL ? "Email" : "Mobile"} Code
        </Button>
      </div>
    </div>
  );
}

export default AuthContainer(VerifyOTPForm);
