import React, { useEffect, useState } from "react";
import AuthContainer from "../../store/container/AuthContainer";
import { AuthReducerProps } from "../../store/reducers/authReducer";
import { ProfileCompletionStatusEnum } from "../../enums/profileCompletionStatus.enum";
import { useSelector } from "react-redux";
import { RootReducerProps } from "../../store/reducers";

interface profileCompletionStatusHooksProps extends AuthReducerProps {}

function useProfileCompletionStatus() {
  const [aadhaarCompleted, setAadhaarCompleted] = useState(false);

  const [aadhaarVerified, setAadhaarVerified] = useState(false);

  const [panCompleted, setPanCompleted] = useState(false);

  const [panVerified, setPanVerified] = useState(false);

  const businessAssociate = useSelector(
    ({ auth }: RootReducerProps) => auth.businessAssociate
  );

  useEffect(() => {
    setAadhaarCompleted(
      businessAssociate?.profileCompletionStatus ===
        ProfileCompletionStatusEnum.AADHAAR_COMPLETED
    );
    setAadhaarVerified(!!businessAssociate?.isAadhaarVerified);
    setPanCompleted(
      businessAssociate?.profileCompletionStatus ===
        ProfileCompletionStatusEnum.PAN_COMPLETED
    );
    setPanVerified(!!businessAssociate?.isPanVerified);
  }, [businessAssociate]);

  return { aadhaarCompleted, aadhaarVerified, panCompleted, panVerified };
}

export default useProfileCompletionStatus;
