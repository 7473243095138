import React, { useState, useEffect } from "react";
import { Form, Formik } from "formik";
import InputField from "../../../shared/components/InputField";
import { Button, Modal, Row, Col } from "antd";
import "./verifyAadhaarForm.scss";
import { verifyAadhaarFormValidation } from "./verifyAadhaarFormValidation";
import OnboardingService from "../../../services/OnboardingService/onboarding.service";
import OtpInput from "react-otp-input";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import useProfileCompletionStatus from "../../../shared/hooks/profileCompletionStatusHooks";
import AttachmentUpload from "../../../shared/components/AttachmentUpload";
import { BusinessAssociate } from "../../../models/BusinessAssociate/businessAssociate.model";
import OnboardingContainer from "../../../store/container/OnboardingContainer";
import { OnboardingReducerProps } from "../../../store/reducers/onboardingReducer";
import { ProfileCompletionStatusEnum } from "../../../enums/profileCompletionStatus.enum";

interface VerifyAadhaarFormProps
  extends AuthReducerProps,
    OnboardingReducerProps {}

interface VerifyAadhaarProps {
  aadhaarNumber?: string;
}

function VerifyAadhaarForm({
  setActiveStep,
  activeStep,
  businessAssociate,
}: VerifyAadhaarFormProps) {
  const [aadhaarVerificationFailed, setAadhaarVerificationFailed] =
    useState(false);

  const [showVerificationModal, setShowVerificationModal] = useState(false);

  const [aadhaarOTP, setAadhaarOTP] = useState<string>();

  const handleToggleVerificationModal = () => {
    setAadhaarOTP("");
    setShowVerificationModal(!showVerificationModal);
  };

  const [formValues, setFormValues] = useState<BusinessAssociate>(
    businessAssociate || new BusinessAssociate()
  );

  const [formLoading, setFormLoading] = useState(false);

  const [verifyLoading, setVerifyLoading] = useState(false);

  const [otpFormLoading, setOTPFormLoading] = useState(false);

  const { aadhaarVerified } = useProfileCompletionStatus();

  const handleSubmit = (values: BusinessAssociate) => () => {
    const businessAssociate = Object.assign(new BusinessAssociate(), values);
    businessAssociate.profileCompletionStatus =
      ProfileCompletionStatusEnum.AADHAAR_COMPLETED;
    setFormLoading(true);
    OnboardingService.updateBADetails(
      businessAssociate,
      () => {
        setActiveStep(activeStep + 1);
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  const handleVerify = (values: BusinessAssociate) => {
    if (values.aadhaarNumber) {
      setFormValues(values);
      setVerifyLoading(true);
      OnboardingService.initiateAadhaar(
        values.aadhaarNumber,
        (success: boolean) => {
          if (success) {
            setShowVerificationModal(true);
          } else {
            Modal.success({
              icon: null,
              className: "verify-aadhaar-form__failure-modal",
              width: "35%",
              closable: true,
              okText: "Upload Document",
              onOk: () => {
                setAadhaarVerificationFailed(true);
              },
              okButtonProps: { style: { float: "none" } },
              content: (
                <div className="text-center">
                  <div>
                    <CloseOutlined className="verify-aadhaar-form__failure-icon" />
                  </div>
                  <h2>Your Aadhaar is failed!</h2>
                </div>
              ),
            });
          }
        },
        () => {},
        () => {
          setVerifyLoading(false);
        }
      );
    }
  };

  const handleSubmitOTP = () => {
    if (aadhaarOTP) {
      setOTPFormLoading(true);
      OnboardingService.verifyAadhaar(
        aadhaarOTP,
        (success: boolean) => {
          if (success) {
            Modal.success({
              icon: null,
              className: "verify-aadhaar-form__success-modal",
              width: "35%",
              closable: true,
              content: (
                <div className="text-center">
                  <div>
                    <CheckOutlined className="verify-aadhaar-form__success-icon" />
                  </div>
                  <h2 className="text-primary">Success!</h2>
                  <p className="text-secondary">
                    Your Aadhaar has been verified successfully.
                  </p>
                </div>
              ),
            });
          }
        },
        () => {},
        () => {
          setOTPFormLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    setAadhaarVerificationFailed(
      !!(
        businessAssociate?.aadhaarNumber &&
        !businessAssociate?.isAadhaarVerified
      )
    );
  }, []);

  return (
    <div className="verify-aadhaar-form">
      <Formik
        enableReinitialize
        initialValues={formValues}
        onSubmit={handleVerify}
        validationSchema={verifyAadhaarFormValidation}
      >
        {({ values, errors, isValid, dirty, setFieldValue }) => {
          return (
            <Form>
              <Row>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{ position: "relative" }}
                >
                  <InputField
                    required
                    title="Aadhaar number"
                    type="text"
                    name="aadhaarNumber"
                    placeholder="Enter Number"
                    readOnly={aadhaarVerified}
                  />
                  {aadhaarVerified && (
                    <div className="verify-aadhaar-form__verified">
                      <CheckOutlined className="verify-aadhaar-form__verified-icon" />
                    </div>
                  )}
                  {aadhaarVerificationFailed && (
                    <div className="verify-aadhaar-form__failed">
                      <CloseOutlined className="verify-aadhaar-form__failed-icon" />
                    </div>
                  )}
                  {!aadhaarVerified && (
                    <div className="verify-aadhaar-form__submit">
                      <Button
                        className="secondary-btn"
                        htmlType="submit"
                        disabled={!isValid}
                        loading={verifyLoading}
                      >
                        Verify {aadhaarVerificationFailed ? "Again" : ""}
                      </Button>
                    </div>
                  )}
                  {aadhaarVerified && (
                    <div className="verify-aadhaar-form__submit">
                      <Button
                        className="secondary-btn"
                        htmlType="button"
                        onClick={() => {
                          setActiveStep(activeStep + 1);
                        }}
                      >
                        Proceed
                      </Button>
                    </div>
                  )}
                </Col>
              </Row>
              {aadhaarVerificationFailed && (
                <React.Fragment>
                  <h3 className="text-primary">Note</h3>
                  <p className="text-secondary-black">
                    If Aadhaar fails upload front and back images and click
                    continue to proceed.
                  </p>
                  <Row gutter={[20, 20]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <AttachmentUpload
                        accept="image/jpeg,image/png,image/jpg, application/pdf"
                        valueKey="aadhaarFrontPage"
                        name="aadhaarFrontPageId"
                        value={values?.aadhaarFrontPage}
                        placeholder="Aadhaar Front (jpeg, png, pdf) (Max 5MB)"
                        setFieldValue={setFieldValue}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <AttachmentUpload
                        accept="image/jpeg,image/png,image/jpg, application/pdf"
                        valueKey="aadhaarBackPage"
                        name="aadhaarBackPageId"
                        value={values?.aadhaarBackPage}
                        placeholder="Aadhaar Back (jpeg, png, pdf) (Max 5MB)"
                        setFieldValue={setFieldValue}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <AttachmentUpload
                        accept="image/jpeg,image/png,image/jpg, application/pdf"
                        valueKey="otherIdentityProof"
                        name="otherIdentityProofId"
                        value={values?.otherIdentityProof}
                        placeholder="Other Identity Proof (jpeg, png, pdf) (Max 5MB)"
                        setFieldValue={setFieldValue}
                      />
                    </Col>
                  </Row>
                  <div className="mt-3 text-right">
                    <Button
                      htmlType="button"
                      onClick={handleSubmit(values)}
                      type="primary"
                      disabled={
                        formLoading ||
                        !values?.aadhaarFrontPageId ||
                        !values?.aadhaarBackPageId ||
                        !values?.otherIdentityProofId
                      }
                      loading={formLoading}
                    >
                      Continue
                    </Button>
                  </div>
                </React.Fragment>
              )}
            </Form>
          );
        }}
      </Formik>
      <Modal
        onCancel={handleToggleVerificationModal}
        visible={showVerificationModal}
        destroyOnClose
        footer={null}
        className="verify-aadhaar-form__modal"
      >
        <h2 className="text-primary">OTP Verification</h2>
        <p>Please enter the verification code sent to your mobile.</p>
        <div className="verify-aadhaar-form__otp-wrapper">
          <OtpInput
            value={aadhaarOTP}
            onChange={(otp: string) => setAadhaarOTP(otp)}
            numInputs={6}
            separator={<span>-</span>}
          />
        </div>
        <div className="verify-aadhaar-form__otp-submit">
          <Button
            type="primary"
            disabled={!(aadhaarOTP?.length === 6) || otpFormLoading}
            loading={otpFormLoading}
            onClick={handleSubmitOTP}
          >
            Verify
          </Button>
        </div>
        <p className="verify-aadhaar-form__resend-wrapper">
          Didn't receive the code?{" "}
          <Button
            type="link"
            loading={verifyLoading}
            onClick={() => handleVerify(formValues)}
          >
            Resend Code
          </Button>
        </p>
        <p>
          If you have any issues in receiving/verifying the OTP, please{" "}
          <Button
            type="link"
            onClick={() => {
              setAadhaarVerificationFailed(true);
              setShowVerificationModal(false);
            }}
          >
            Click here to upload the documents
          </Button>
        </p>
        <p className="verify-aadhaar-form__note">
          I hereby consent to provide my Aadhaar Number, Biometric and/or One
          Time Pin (OTP) data for Aadhaar based authentication for the purpose
          of establishing my identity through my Aadhaar number.
        </p>
        {/* <p className="text-primary text-bold"> Full Name, DOB, Address.</p> */}
      </Modal>
    </div>
  );
}

export default OnboardingContainer(AuthContainer(VerifyAadhaarForm));
