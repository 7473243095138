import React, { useEffect, useState } from "react";
import { FieldArray, Form, Formik } from "formik";
import "./baQualificationForm.scss";
import { BusinessAssociate } from "../../../models/BusinessAssociate/businessAssociate.model";
import { Button, Row, Col } from "antd";
import { Qualification } from "../../../models/Qualification/qualification.model";
import InputField from "../../../shared/components/InputField";
import { QualificationTypeEnum } from "../../../enums/qualificationType.enum";
import OnboardingContainer from "../../../store/container/OnboardingContainer";
import { OnboardingReducerProps } from "../../../store/reducers/onboardingReducer";
import { ProfileCompletionStatusEnum } from "../../../enums/profileCompletionStatus.enum";
import OnboardingService from "../../../services/OnboardingService/onboarding.service";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import DatePickerField from "../../../shared/components/DatePickerField";
import MetaService, {
  IDropdownOption,
} from "../../../services/MetaService/meta.service";
import { Degree } from "../../../models/Degree/degree.model";
import DropdownField from "../../../shared/components/DropdownField";
import moment from "moment";
import { qualificationFormValidation } from "./qualificationFormValidation";
import { StudentBAEnum } from "../../../enums/studentBA.enum";

interface BAQualificationFormProps
  extends OnboardingReducerProps,
    AuthReducerProps {}

function BAQualificationForm({
  setActiveStep,
  activeStep,
  businessAssociate,
}: BAQualificationFormProps) {
  const [formValues, setFormValues] = useState(new BusinessAssociate());

  const [formLoading, setFormLoading] = useState(false);

  const [degreeOptions, setDegreeOptions] = useState<IDropdownOption[]>([]);

  const handleSubmit = (values: BusinessAssociate) => {
    const businessAssociate = Object.assign(new BusinessAssociate(), values);
    businessAssociate.profileCompletionStatus =
      ProfileCompletionStatusEnum.QUALIFICATION_DETAILS_COMPLETE;
    setFormLoading(true);
    OnboardingService.updateBADetails(
      businessAssociate,
      () => {
        setActiveStep(activeStep + 1);
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  useEffect(() => {
    const ba = Object.assign(new BusinessAssociate(), businessAssociate);
    if (
      businessAssociate?.qualifications &&
      businessAssociate?.qualifications.length === 0
    ) {
      ba.qualifications = [
        Object.assign(new Qualification(), {
          ...new Qualification(),
          qualificationType: QualificationTypeEnum.EDUCATIONAL,
        }),
        Object.assign(new Qualification(), {
          ...new Qualification(),
          qualificationType: QualificationTypeEnum.PROFESSIONAL,
        }),
      ];
    }
    setFormValues(ba);
  }, [businessAssociate]);

  useEffect(() => {
    MetaService.fetchDegrees(
      (degrees: Degree[]) => {
        setDegreeOptions(
          degrees.map((degree) => ({ label: degree.name, value: degree.name }))
        );
      },
      () => {},
      () => {}
    );
  }, []);

  return (
    <div className="ba-qualification-form">
      <Formik
        initialValues={formValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={qualificationFormValidation}
      >
        {({ values, errors, isValid, dirty, setFieldValue }) => {
          return (
            <Form>
              <h2 className="text-primary">Qualification</h2>
              <FieldArray
                name="qualifications"
                render={(arrayHelpers) => (
                  <div className="ba-qualification-form__qualifications">
                    {values.qualifications &&
                      values.qualifications.length > 0 &&
                      values.qualifications.map(
                        (qualification: Qualification, fieldIndex: number) => {
                          return (
                            <div key={fieldIndex}>
                              <Row
                                key={fieldIndex}
                                gutter={[20, 10]}
                                className="ba-qualification-form__qualification-field"
                              >
                                <Col span={24}>
                                  <h3 className="text-primary">
                                    {qualification.qualificationType ===
                                    QualificationTypeEnum.PROFESSIONAL
                                      ? "Professional Qualification"
                                      : "Highest Educational Qualification"}
                                  </h3>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                  <InputField
                                    required={
                                      qualification.qualificationType ===
                                      QualificationTypeEnum.EDUCATIONAL
                                    }
                                    title="Institution Name"
                                    type="text"
                                    name={`qualifications[${fieldIndex}].institutionName`}
                                    placeholder="Enter Institution name"
                                  />
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                  {qualification.qualificationType ===
                                  QualificationTypeEnum.EDUCATIONAL ? (
                                    <DropdownField
                                      required
                                      showSearch
                                      title={
                                        businessAssociate?.studentBAAgreed ===
                                        StudentBAEnum.AGREED_YES
                                          ? "Pursuing Degree Name"
                                          : "Degree Name"
                                      }
                                      name={`qualifications[${fieldIndex}].specialityName`}
                                      options={degreeOptions}
                                      dropdownMatchSelectWidth={true}
                                      value={
                                        values?.qualifications &&
                                        values?.qualifications.length > 0
                                          ? values?.qualifications[fieldIndex]
                                              .specialityName
                                          : undefined
                                      }
                                      setFieldValue={setFieldValue}
                                      placeHolder="Select Degree"
                                    />
                                  ) : (
                                    <InputField
                                      title="Certification Name"
                                      type="text"
                                      name={`qualifications[${fieldIndex}].specialityName`}
                                      placeholder="Enter Degree Name"
                                    />
                                  )}
                                </Col>
                                {qualification.qualificationType ===
                                  QualificationTypeEnum.PROFESSIONAL && (
                                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <InputField
                                      title="Register number"
                                      type="text"
                                      name={`qualifications[${fieldIndex}].registerNumber`}
                                      placeholder="Enter Register number"
                                    />
                                  </Col>
                                )}
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                  <DatePickerField
                                    required={
                                      qualification.qualificationType ===
                                      QualificationTypeEnum.EDUCATIONAL
                                    }
                                    title={
                                      businessAssociate?.studentBAAgreed ===
                                      StudentBAEnum.AGREED_YES
                                        ? "Expected Year of Completion"
                                        : "Year of completion"
                                    }
                                    name={`qualifications[${fieldIndex}].yearOfCompletion`}
                                    format="YYYY"
                                    disabledDate={(d) => {
                                      const lowerLimit = moment(
                                        "01/01/1970",
                                        "MM/DD/YYYY"
                                      );
                                      return d > moment() || d < lowerLimit;
                                    }}
                                    picker="year"
                                    defaultValue={
                                      values.qualifications &&
                                      values.qualifications.length > 0
                                        ? values?.qualifications[fieldIndex]
                                            .yearOfCompletion
                                        : undefined
                                    }
                                    setFieldValue={setFieldValue}
                                  />
                                </Col>
                              </Row>
                            </div>
                          );
                        }
                      )}
                  </div>
                )}
              />
              <div className="mt-5 text-right">
                {/*   <Button type="default" onClick={onCancel}>
                    Cancel
                  </Button>*/}
                <Button
                  type="primary"
                  htmlType="submit"
                  className="ml-2"
                  loading={formLoading}
                  disabled={!isValid || formLoading}
                >
                  Proceed
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default AuthContainer(OnboardingContainer(BAQualificationForm));
