import React, { FC } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import RegisterForm from "../../views/Auth/RegisterForm";
import LoginForm from "../../views/Auth/LoginForm";
import { RouterProps } from "../../shared/types/route.type";
import { AppRoutes } from "../../routes/routeConstants/appRoutes";
import ForgotPasswordForm from "./ForgotPasswordForm";
import isAuthenticated from "../../shared/components/HOC/requireAuth";
import VerifyOTPWrapper from "./VerifyOTPWrapper";

const authRouter = () => {
  const routes: RouterProps[] = [
    { exact: true, path: AppRoutes.REGISTER, component: RegisterForm },
    {
      exact: true,
      path: AppRoutes.FORGOT_PASSWORD,
      component: ForgotPasswordForm,
    },
    { exact: true, path: AppRoutes.LOGIN, component: LoginForm },
    {
      exact: true,
      path: AppRoutes.VERIFY_OTP,
      component: isAuthenticated(VerifyOTPWrapper),
    },
  ];

  return (
    <Switch>
      {routes.map(({ component, ...routerProps }) => (
        <Route {...routerProps} component={component as FC} />
      ))}
      <Redirect exact strict from={AppRoutes.AUTH} to={AppRoutes.LOGIN} />
    </Switch>
  );
};

export default authRouter;
