import { SET_ACTIVE_STEP } from "../definitions/onboardingConstants";

export interface SetActiveStepAction {
  type: typeof SET_ACTIVE_STEP;
  payload: { activeStep: number };
}

export const setActiveStep = (activeStep: number): SetActiveStepAction => ({
  type: SET_ACTIVE_STEP,
  payload: {
    activeStep,
  },
});
