export const ApiRoutes = {
  BASE_URL: process.env.REACT_APP_API_BASE_URL,
  SIGN_IN: "/user_management/ba/sign_in",
  SIGN_OUT: "/user_management/ba/sign_out",
  SIGN_UP: "/user_management/ba/signup",
  RESET_PASSWORD: "/user_management/ba/reset_password",
  PROVINCES: "/meta/provinces",
  CITIES: "/meta/provinces/:provinceId/cities",
  SPECIALITIES: "/meta/specialities",
  INITIATE_AADHAAR: "/user_management/ba/initiate_aadhaar_verification",
  VERIFY_AADHAAR: "/user_management/ba/verify_aadhaar",
  VERIFY_PAN: "/user_management/ba/verify_pan",
  VERIFY_EMAIL: "/user_management/ba/verify_email",
  VERIFY_MOBILE: "/user_management/ba/verify_mobile",
  SEND_OTP: "/user_management/ba/send_otp",
  UPLOAD_FILES: "/file_management/uploaded_files",
  BA_PROFILE: "/user_management/ba/profile",
  REQUEST_AGREEMENT: "/user_management/ba/request_agreement",
  VERIFY_ACCOUNT: "/user_management/ba/verify_account",
};
