import React, { useState } from "react";
import { Form, Formik } from "formik";
import InputField from "../../../shared/components/InputField";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import { BusinessAssociate } from "../../../models/BusinessAssociate/businessAssociate.model";
import { resetPasswordFormValidation } from "./resetPasswordFormValidation";
import AuthService from "../../../services/AuthService/auth.service";

interface ResetPasswordFormProps {
  email?: string;
  mobile?: string;
}

function ResetPasswordForm({ email, mobile }: ResetPasswordFormProps) {
  const [formValues, setFormValues] = useState(new BusinessAssociate());

  const [formLoading, setFormLoading] = useState(false);

  const history = useHistory();

  const handleSubmit = (values: BusinessAssociate) => {
    const businessAssociate = Object.assign(new BusinessAssociate(), values);
    businessAssociate.email = email;
    businessAssociate.mobile = mobile;
    setFormLoading(true);
    AuthService.resetPassword(
      businessAssociate,
      () => {
        history.push(AppRoutes.LOGIN);
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  return (
    <div className="reset-password-form">
      <Formik
        initialValues={formValues}
        onSubmit={handleSubmit}
        validationSchema={resetPasswordFormValidation}
      >
        {({ values, errors, isValid, dirty, setFieldValue }) => {
          return (
            <Form>
              <h1>Reset Password</h1>
              <InputField
                title="Enter OTP"
                type="text"
                name="passwordResetOTP"
                placeholder="Enter OTP"
              />
              <InputField
                title="New Password"
                type="password"
                name="password"
                placeholder="Enter Password"
              />
              <InputField
                title="Confirm Password"
                type="password"
                name="confirmPassword"
                placeholder="Enter Password"
              />
              <Button
                type="primary"
                htmlType="submit"
                disabled={!isValid || !dirty || formLoading}
                loading={formLoading}
              >
                Reset Password
              </Button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default ResetPasswordForm;
