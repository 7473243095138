import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize, serialize } from "serializr";
import { store } from "../../store";
import {
  AUTHENTICATED,
  UNAUTHENTICATED,
} from "../../store/definitions/authConstants";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { BusinessAssociate } from "../../models/BusinessAssociate/businessAssociate.model";
import { OtpTypeEnum } from "../../enums/otpType.enum";
import OnboardingService from "../OnboardingService/onboarding.service";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";

export default class AuthService {
  static loginUser(
    businessAssociate: BusinessAssociate,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const businessAssociateJSON = {
      business_associate: serialize(businessAssociate),
    };
    axiosInstance
      .post(ApiRoutes.SIGN_IN, businessAssociateJSON)
      .then((response) => {
        const businessAssociate = deserialize(
          BusinessAssociate,
          response.data["business_associate"]
        );
        localStorage.setItem("token", JSON.stringify(response.data["token"]));
        localStorage.setItem(
          "businessAssociate",
          JSON.stringify(businessAssociate)
        );
        store.dispatch({
          type: AUTHENTICATED,
          payload: {
            authenticated: true,
            businessAssociate,
          },
        });
        onSuccess();
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static registerUser(
    businessAssociate: BusinessAssociate,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const baJSON = { business_associate: serialize(businessAssociate) };
    axiosInstance
      .post(ApiRoutes.SIGN_UP, baJSON)
      .then((response) => {
        const businessAssociate = deserialize(
          BusinessAssociate,
          response.data["business_associate"]
        );
        localStorage.setItem("token", JSON.stringify(response.data["token"]));
        localStorage.setItem(
          "businessAssociate",
          JSON.stringify(businessAssociate)
        );
        store.dispatch({
          type: AUTHENTICATED,
          payload: {
            authenticated: true,
            businessAssociate,
          },
        });
        onSuccess();
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static verifyOTP(
    otp: string,
    otpType: OtpTypeEnum,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL =
      otpType === OtpTypeEnum.EMAIL
        ? ApiRoutes.VERIFY_EMAIL
        : ApiRoutes.VERIFY_MOBILE;
    let otpJSON = {};
    if (otpType === OtpTypeEnum.EMAIL) {
      otpJSON = {
        email_otp: otp,
      };
    } else {
      otpJSON = {
        mobile_otp: otp,
      };
    }
    axiosInstance
      .post(API_URL, otpJSON)
      .then((response) => {
        if (response.data.success) {
          Notification({
            type: NotificationTypes.SUCCESS,
            message: `${
              otpType === OtpTypeEnum.EMAIL ? "Email" : "Mobile"
            } verified`,
          });
          OnboardingService.showBADetails(
            () => {
              onSuccess();
            },
            () => {},
            () => {}
          );
        }
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static sendOTP(
    businessAssociate: BusinessAssociate,
    otpType: OtpTypeEnum,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    let baJSON = {};
    if (otpType === OtpTypeEnum.EMAIL) {
      baJSON = {
        email: businessAssociate.email,
      };
    } else {
      baJSON = {
        mobile: businessAssociate.mobile,
      };
    }
    axiosInstance
      .post(ApiRoutes.SEND_OTP, baJSON)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static resetPassword(
    businessAssociate: BusinessAssociate,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const baJSON = { business_associate: serialize(businessAssociate) };
    axiosInstance
      .post(ApiRoutes.RESET_PASSWORD, baJSON)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static logout(onSuccess: Function, onError: Function, onFinal: () => void) {
    const storeAdmin = localStorage.getItem("storeAdmin");
    if (storeAdmin) {
      return axiosInstance
        .delete(ApiRoutes.SIGN_OUT)
        .then((response) => {
          localStorage.clear();
          store.dispatch({
            type: UNAUTHENTICATED,
            payload: {
              authenticated: false,
            },
          });
          onSuccess();
        })
        .catch((error) => {
          onError(error);
        })
        .finally(() => {
          onFinal();
        });
    }
  }
}
