import React, { useState } from "react";
import "./baReferenceForm.scss";
import { BusinessAssociate } from "../../../models/BusinessAssociate/businessAssociate.model";
import { CloseOutlined } from "@ant-design/icons";
import OnboardingContainer from "../../../store/container/OnboardingContainer";
import { OnboardingReducerProps } from "../../../store/reducers/onboardingReducer";
import { FieldArray, FieldArrayRenderProps, Form, Formik } from "formik";
import { Qualification } from "../../../models/Qualification/qualification.model";
import { Button, Col, Row } from "antd";
import { QualificationTypeEnum } from "../../../enums/qualificationType.enum";
import InputField from "../../../shared/components/InputField";
import { ReferenceContact } from "../../../models/ReferenceContact/referenceContact.model";
import DropdownField from "../../../shared/components/DropdownField";
import { ProfileCompletionStatusEnum } from "../../../enums/profileCompletionStatus.enum";
import OnboardingService from "../../../services/OnboardingService/onboarding.service";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { baReferenceFormValidation } from "./baReferenceFormValidation";
interface BAReferenceFormProps
  extends OnboardingReducerProps,
    AuthReducerProps {}

function BAReferenceForm({
  setActiveStep,
  activeStep,
  businessAssociate,
}: BAReferenceFormProps) {
  const [formValues, setFormValues] = useState(
    businessAssociate || new BusinessAssociate()
  );

  const [formLoading, setFormLoading] = useState(false);

  const sourceOptions = [
    { label: "Referral", value: "referral" },
    { label: "Internal Employees", value: "internal_employees" },
    { label: "Student LEAD Program", value: "student_lead_program" },
  ];

  const handleAddReference = (arrayHelpers: FieldArrayRenderProps) => () => {
    arrayHelpers.push(new ReferenceContact());
  };

  const handleSubmit = (values: BusinessAssociate) => {
    const businessAssociate = Object.assign(new BusinessAssociate(), values);
    businessAssociate.profileCompletionStatus =
      ProfileCompletionStatusEnum.REFERENCES_COMPLETE;
    setFormLoading(true);
    OnboardingService.updateBADetails(
      businessAssociate,
      () => {
        setActiveStep(activeStep + 1);
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  return (
    <div className="ba-reference-form">
      <Formik
        initialValues={formValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={baReferenceFormValidation}
      >
        {({ values, errors, isValid, dirty, setFieldValue }) => {
          return (
            <Form>
              <h2 className="text-primary">References</h2>
              <FieldArray
                name="referenceContacts"
                render={(arrayHelpers) => (
                  <div className="ba-reference-form__references">
                    {values.referenceContacts &&
                      values.referenceContacts.length > 0 &&
                      values.referenceContacts.map(
                        (reference: ReferenceContact, fieldIndex: number) => {
                          return (
                            <div key={fieldIndex}>
                              <Row
                                align="top"
                                key={fieldIndex}
                                gutter={[20, 10]}
                                className="ba-reference-form__reference-field"
                              >
                                <Col xs={24} sm={24} md={7} lg={7} xl={7}>
                                  <InputField
                                    required
                                    title="Name"
                                    type="text"
                                    name={`referenceContacts[${fieldIndex}].name`}
                                    placeholder="Enter name"
                                  />
                                </Col>
                                <Col xs={24} sm={24} md={7} lg={7} xl={7}>
                                  <InputField
                                    required
                                    title="Email"
                                    type="email"
                                    name={`referenceContacts[${fieldIndex}].email`}
                                    placeholder="Enter email"
                                  />
                                </Col>
                                <Col xs={24} sm={24} md={7} lg={7} xl={7}>
                                  <InputField
                                    required
                                    title="Mobile"
                                    type="text"
                                    name={`referenceContacts[${fieldIndex}].mobile`}
                                    placeholder="Enter Mobile"
                                  />
                                </Col>
                                <Col
                                  xs={24}
                                  sm={24}
                                  md={1}
                                  lg={1}
                                  xl={1}
                                  className="ba-reference-form__remove-wrapper"
                                >
                                  <Button
                                    onClick={() => {
                                      arrayHelpers.remove(fieldIndex);
                                    }}
                                    className="ba-reference-form__close-icon"
                                    icon={<CloseOutlined />}
                                  />
                                  <Button
                                    className="delete-btn ba-reference-form__remove-btn"
                                    onClick={() => {
                                      arrayHelpers.remove(fieldIndex);
                                    }}
                                  >
                                    Remove
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          );
                        }
                      )}
                    <div>
                      <Button
                        type="primary"
                        onClick={handleAddReference(arrayHelpers)}
                      >
                        {" "}
                        Add{" "}
                      </Button>
                    </div>
                  </div>
                )}
              />
              <Row className="mt-3">
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <DropdownField
                    title="Where did you hear about us?"
                    name="source"
                    options={sourceOptions}
                    value={values.source}
                    setFieldValue={setFieldValue}
                    placeHolder="Select Source"
                  />
                </Col>
              </Row>
              <div className="mt-5 text-right">
                {/*   <Button type="default" onClick={onCancel}>
                    Cancel
                  </Button>*/}
                <Button
                  type="primary"
                  htmlType="submit"
                  className="ml-2"
                  loading={formLoading}
                  disabled={!isValid || formLoading}
                >
                  Proceed
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default AuthContainer(OnboardingContainer(BAReferenceForm));
